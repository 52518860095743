import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-valid-return',
  templateUrl: './valid-return.component.html',
  styleUrls: ['./valid-return.component.css']
})
export class ValidReturnComponent implements OnInit {

  allReturn: any = []

  constructor(private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this.listReturns()
  }

  async listReturns() {
    await this.transServices.getReturnsValid("valid").then((res: any) => {
      console.log("allReturn")
      console.log(res)
      if (res) {
        this.allReturn = res;
      }
    });
  }

}
