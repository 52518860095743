import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListCommentsComponent } from 'src/app/components/shared/dialogs/list-comments/list-comments.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-all-commandes',
  templateUrl: './all-commandes.component.html',
  styleUrls: ['./all-commandes.component.css']
})
export class AllCommandesComponent implements OnInit {

  heading = 'Dropdowns';
  subheading = 'Multiple styles, actions and effects are available for the ArchutectUI dropdown buttons.';
  icon = 'pe-7s-umbrella icon-gradient bg-sunny-morning';


  villes = ["Selectionnez une ville",
    'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'
  ];

  status = ["Selectionnez status", "validated", "transporting_depot", "prepared", "submited", "transporting", "transported", "arrived", "delivered", "paied", "returned", "refused", "canceled"];
  selectedVille: string = '';
  selectedStatus: string = '';
  
  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  cmdList: Array<any> = [];
  statusCmds: any = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    // this.getInfosUser()
    // this.listCmdsClient()
    this.searchCmds("", "", "", "")
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }
  
  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.selectedStatus = ""
    } else {
      this.selectedStatus = event.target.value;
    }
  }

  selectChangeVille(event: any) {
    if (event.target.value == "Selectionnez une ville") {
      this.selectedVille = "";
    } else {
      this.selectedVille = event.target.value;
    }
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if(this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if(this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if(this.currentPage >= 5 && this.currentPage < this.pages.length-5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage-4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length-10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  async filterCmds(ref, numero, datein, datefin) {
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }
  
  async searchCmds(ref, numero, datein, datefin) {
    // window.scrollTo(0, 380);

    await this.cmdsService.filterCmds(this.currentPage, ref, 
        this.selectedStatus, this.selectedVille, numero, datein, datefin).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdList = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
        this.splicePages()
      }
    });
  }
  nextPage(ref, numero, datein, datefin) {
    this.currentPage++
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }

  previousPage(ref, numero, datein, datefin) {
    this.currentPage--
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page, ref, numero, datein, datefin) {
    this.currentPage = page
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }

  async updateStatusCmd(cmd, status) {
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;
    if (confirm('Etes vous sûre?'))
      await this.cmdsService.updateCmdStatus(cmd, status).then((res) => {
        if (res) {
          alert('Commande mis à jour avec succès');
          cmd.etat_cmd = "canceled"
        }
        else {
          alert("Une erreur s'est produite");
        }
      });
  }

  listComments(cmd) {
    if (cmd.comments.length > 0) {
      const confirmDialog = this.dialog.open(ListCommentsComponent, {
        data: {
          cmd: cmd,
        }
      });
    }
  }
}
