import { Component, OnInit } from '@angular/core';
import { SocietiesService } from 'src/app/services/societies/societies.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-add-business-accounts',
  templateUrl: './add-business-accounts.component.html',
  styleUrls: ['./add-business-accounts.component.css']
})
export class AddBusinessAccountsComponent implements OnInit {

  allSocties:any = []
  selectedSociety: any = null;

  constructor(private transServices: TransportorsService,
    private societyService: SocietiesService) { }

  ngOnInit(): void {
    this.allSocieties()
  }

  async allSocieties() {
    await this.transServices.allSocieties().then((res: any) => {
      if (res) {
        console.log("resssssssss : ")
        console.log(res)
        this.allSocties = res;
      }
    });
  }

  addBusinessAccount() {
    if (this.selectedSociety != null && this.selectedSociety != "null" ) {
      // if (true) {
      if (confirm('Etes vous sûre?')) {
        this.societyService.addBusinessAccount(this.selectedSociety).then((res: any) => {
          // console.log("ressssss add cat : ")
          // console.log(res)
          if (res == true) {
            location.href = '/business-accounts';
          } else {
            alert(res.msg);
          }
        });
      }
    } else {
      alert('Les données de compte business est invalide');
    }
  }

}
