<div id="body">
    <header class="clearfix">
        <div id="logo">
            <img src="../../../../assets/images/logo-facture.png">
        </div>
        <div id="company">
            <h2 class="name">Facture N° {{account.date_action | date: 'y'}}/{{account.ref_facture}}</h2>
            <div><b>Date : {{nowDate | date:'d MMM y'}}</b></div>
            <div><b>Devise : TND</b></div>
            <div>Prix : HT</div>
            <!-- <div><a href="mailto:company@example.com">company@example.com</a></div> -->
        </div>

    </header>
    <main>
        <div id="details" class="clearfix">
            <div id="client">
                <h2 class="name">WASSALNI EXPRESS</h2>
                <table style="margin-bottom: 2px;">
                    <tbody>
                        <tr>
                            <td class="header header-bold">Adresse : </td>
                            <td class="header">28, Rue de l'Energie Atomique, Charguia 1<br>
                            1000, Tunis, Tunisia</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Tél : </td>
                            <td class="header">21 733 418</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Mail : </td>
                            <td class="header">wassali.express@gmail.com</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">M.F. : </td>
                            <td class="header">1738199M/A/M/000</td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="to">INVOICE TO:</div>
                <div class="address">796 Silver Harbour, TX 79273, US</div>
                <div class="email"><a href="mailto:john@example.com">john@example.com</a></div> -->
            </div>
            <div id="invoice">
                <table style="margin-bottom: 2px;">
                    <tbody>
                        <tr>
                            <td colspan="2" style="border: none;"></td>
                        </tr>
                        <tr>
                            <td class="header header-bold">A : </td>
                            <td class="header"><b>{{client?.prenom_client + " " + client?.nom_client}}</b></td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Adresse : </td>
                            <td class="header"><b>{{client?.adresse_client}}</b><br>
                               <!--  2073 - Ariana<br> -->
                            <b>{{client?.numTel_client}}</b></td>
                        </tr>
                        <!-- <tr>
                            <td class="header header-bold">Code client : </td>
                            <td class="header">---</td>
                        </tr> -->
                        <tr>
                            <td class="header header-bold">Code TVA : </td>
                            <td class="header"></td>
                        </tr>
                    </tbody>
                </table>
                <!-- <h1>INVOICE 3-2-1</h1>
                <div class="date">Date of Invoice: 01/06/2014</div>
                <div class="date">Due Date: 30/06/2014</div> -->
            </div>
        </div>
        <table border="0" cellspacing="0" cellpadding="0">
            <thead>
                <tr style="border: 1px solid;">
                    <th class="title-table">Référence</th>
                    <th class="title-table">Description</th>
                    <th class="title-table">PU HT</th>
                    <th class="title-table">Quantité</th>
                    <th class="title-table">Montant HT</th>
                    <th class="title-table">Taux TVA</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="ref">W01</td>
                    <td class="desc">
                        <h3>Livraison interne</h3>
                    </td>
                    <td class="unit">{{fraisInterneHT | number:'0.3'}}</td>
                    <td class="qty">{{nbrCmdsInterne}}</td>
                    <td class="total">{{fraisInterneHT * nbrCmdsInterne | number:'0.3'}}</td>
                    <td class="total">19%</td>
                </tr>
                <tr *ngIf="nbrCmdsExterne > 0">
                    <td class="ref">W01-2</td>
                    <td class="desc">
                        <h3>Livraison externe</h3>
                    </td>
                    <td class="unit">{{fraisExterneHT | number:'0.3'}}</td>
                    <td class="qty">{{nbrCmdsExterne}}</td>
                    <td class="total">{{fraisExterneHT * nbrCmdsExterne | number:'0.3'}}</td>
                    <td class="total">19%</td>
                </tr>
                <tr>
                    <td class="ref">W02</td>
                    <td class="desc">
                        <h3>Retour</h3>
                    </td>
                    <td class="unit">{{fraisReturnedHT | number:'0.3'}}</td>
                    <td class="qty">{{cmdsNotReturned.length}}</td>
                    <td class="total">{{fraisReturnedHT * cmdsNotReturned.length | number:'0.3'}}</td>
                    <td class="total">19%</td>
                </tr>
                <!-- <tr>
                    <td class="ref">W03</td>
                    <td class="desc">
                        <h3>Échange</h3>
                    </td>
                    <td class="unit">{{fraisReturnedHT | number:'0.3'}}</td>
                    <td class="qty">0</td>
                    <td class="total">0.000</td>
                    <td class="total">19%</td>
                </tr> -->
            </tbody>
            <tfoot>
                <tr>
                    <!-- <td colspan="2"></td> -->
                    <td colspan="4" style="text-align: left;">Total HT</td>
                    <td>{{totalHT | number:'0.3'}}</td>
                    <!-- <td></td> -->
                </tr>
                <tr>
                    <td colspan="6" style="border: none;"></td>
                </tr>
                <tr>
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td">Total TVA</td>
                    <td>{{totalTVA | number:'0.3'}}</td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none;"></td>
                </tr>
                <tr>
                    <td colspan="1" style="border: none;"></td>
                    <td rowspan="2" colspan="1" class="center-td" style="font-weight: bold;">TOTAL</td>
                    <td colspan="2" class="center-td">Droit de timbre</td>
                    <td>0.600</td>
                </tr>
                <tr>
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">TTC</td>
                    <td>{{totalTVA + totalHT + 0.6 | number:'0.3'}}</td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none;"></td>
                </tr>
                <!-- <tr>
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" style="font-weight: bold;">Total TVA</td>
                    <td></td>
                </tr> -->
            </tfoot>
        </table>
        <div id="thanks"><b>Réglement : </b>en espéces</div>
        <!-- <div id="notices">
            <div><b>Arab Tunisian Bank : </b>(Adresse de la banque)</div>
            <div class="notice"><b>RIB : </b> 123456789</div>
        </div> -->
    </main>
    <footer>
        <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td class="signature">Cachet et signature de client</td>
                    <td class="signature">Direction</td>
                </tr>
            </tbody>
        </table>
        <!-- Invoice was created on a computer and is valid without the signature and seal. -->
    </footer>
</div>