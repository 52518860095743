import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SacsService {

  constructor(private http: HttpClient) { }

  uploadVideo(fields) {
    return new Promise((slv) => {
      this.http.post("https://api.marketbey.tn:2043/products/upload", fields).subscribe(
        (data: any) => {
          // console.log(data)
          
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  affectSacToLouagiste(idSac, idUser) {
    return new Promise((slv) => {
      this.http.post(environment.apiAffectSacToLouagiste, {sac: idSac,
        user: idUser}).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allSacs(ville) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllSacsByVille, {ville: ville}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getSacsFromLouagiste(idLouag) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetSacsFromLouagiste, {louagiste: idLouag}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  emptySac(idSac) {
    return new Promise((slv) => {
      this.http.post(environment.apiEmptySac, {sac: idSac}).subscribe(
        (data: any) => {
          // console.log("dataaaaaaa empty")
          // console.log(data)
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  addSac(destination) {
    // console.log(destination)
    return new Promise((slv) => {
      this.http.post(environment.apiAddSac, destination).subscribe(
        (data: any) => {
          // console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getSac(idSac) {
    return new Promise((slv) => {
      this.http.post(environment.apiOneSac + "/" + idSac, {})
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            slv(false);
          }
        );
    });
  }
}
