<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Détails des commandes client
                <div class="page-title-subheading">La liste des commandes non payé et non retournée.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <div class="d-inline-block dropdown"> -->
            <!-- <div class="position-relative form-group col-12">
                    <legend for="client" class="">Clients</legend>
                    <select class="form-control selectric"
                        (change)="selectClient($event)">
                        <option value="Selectionnez le client">
                            Selectionnez le client</option>
                        <option [value]="cl.client._id" *ngFor="let cl of clientsList">
                            {{cl.client.prenom_client + " " + cl.client.nom_client}}</option>
                    </select>
                    <button type="submit" (click)="getClient(keyword)" class="btn btn-primary">Submit</button>
                </div> -->
            <div>
                <form class="form-inline" action="" method="post" onsubmit="return false;"
                    (onsubmit)="getDetailVirrement();">
                    <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                        <select class="form-control selectric" (change)="selectClient($event)">
                            <option value="Selectionnez le client">
                                Selectionnez le client</option>
                            <option [value]="cl.client._id" *ngFor="let cl of clientsList">
                                {{"["+cl.client.company_name+"] " +cl.client.prenom_client + " " + cl.client.nom_client}}</option>
                        </select>
                    </div>
                    <button type="submit" (click)="getDetailVirrement()"
                        class="btn btn-primary">Submit</button>
                </form>
            </div>


            <!-- <div class="search-wrapper">
                    <div class="input-holder">
                        <form class="needs-validation" action="" method="post" onsubmit="return false;"
                            (onsubmit)="listCmdsClient(keyword);"> 
                        <input type="text" id="keyword" name="keyword" class="search-input"
                            placeholder="Type to search" #keyword ngModel>

                        <button type="button" (click)="getClient(keyword)"
                            class="search-icon"><span></span></button>
                         </form> 
                    </div>
                    <button class="close"></button>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <table class="mb-0 table table-dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>05 Jan 2021</th>
                    <th>Quantité & cout</th>
                    <th>Montant</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Frais de livraison</td>
                    <td class="text-center">{{nbrCmdsInterne}} --- {{frais?.frais_interne}} DT</td>
                    <td class="text-right">{{fraisLivred}}</td>
                </tr>
                <tr *ngIf="nbrCmdsExterne > 0">
                    <th scope="row">-</th>
                    <td>Frais de livraison externe</td>
                    <td class="text-center">{{nbrCmdsExterne}} --- {{frais?.frais_externe}} DT</td>
                    <td class="text-right">{{fraisLivredExterned}}</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Frais de retour</td>
                    <td class="text-center">{{cmdsNotReturned.length}} --- {{frais?.frais_retour}} DT
                    </td>
                    <td class="text-right">{{fraisReturned}}</td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Total de remboursement</td>
                    <td></td>
                    <td class="text-right">{{totalCmdsLivred|
                        number:'0.3'}} DT</td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>Total montant à payer</td>
                    <td></td>
                    <td class="text-right">{{totalCmdsLivred - (fraisLivred + fraisLivredExterned +
                        fraisReturned)|
                        number:'0.3'}} DT</td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td>Total frais de livraison</td>
                    <td></td>
                    <td class="text-right">{{fraisLivred + fraisLivredExterned + fraisReturned|
                        number:'0.3'}} DT</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-3"></div>
</div>

<!-- <div class="row space">
    <div class="col-md-2">
    </div>
    <div class="col-md-4">
        <div class="card widget-content bg-success" (click)="listCmdsLivred('LIVREE')">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livré</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{cmdsNotPaied.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card widget-content bg-danger" (click)="listCmdsReturned('RETOURNEE')">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{cmdsNotReturned.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2">
    </div>
</div> -->
<div class="row space">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <!-- <div class="btn-actions-pane-right">
                    <button class="btn btn-warning float-right text-white"
                    (click)="goToNewTab('/facture-paiement-client')"><i
                                class="metismenu-icon pe-7s-print text-white"></i> Imprimer</button>
                </div> -->
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h5 class="text-center">Aucune commande {{tagCmds}} </h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client & Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Total Commande</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td class="text">{{cmd.ref_facture_cmd}}</td>
                            <td class="text-center">{{cmd.date_creation_cmd | date:'d MMM y'}}</td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd.user_destination.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd.user_destination.numtel_destination}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{cmd.user_destination.adresse_destination}}
                                <strong>{{cmd.destination_cmd}}</strong>
                            </td>
                            <td class="text-center">{{cmd.total_ca_cmd+cmd.frais_livraison |
                                number:'0.3'}} DT</td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{statusCmds[cmd.etat_cmd].label}}</div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTabCmd(cmd._id, '/detail-cmd')"
                                                 tabindex="0"
                                                    class="dropdown-item">
                                                    <i class="metismenu-icon pe-7s-news-paper"
                                                        style="margin-right: 10px;"></i>Details
                                                </button>
                                                <button type="button" tabindex="0"
                                                (click)="goToNewTabCmd(cmd._id, '/facture-cmd')"
                                                    class="dropdown-item"><i
                                                        class="metismenu-icon pe-7s-print"
                                                        style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0">
                <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button> -->
                <!-- <button *ngIf="listCmds.length>0 && tagCmds=='RETOURNEE'" type="button"
                    (click)="validateReturnedCmd()"
                    class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> Valider le retour</i></button> -->

                <button *ngIf="listCmds.length>0 && tagCmds=='LIVREE'" (click)="validateLivredCmds()"
                    class="btn-wide btn btn-success"> Valider le paiement </button>
            </div>
        </div>
    </div>
</div>