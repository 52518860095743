import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImageViewerComponent } from 'src/app/components/shared/dialogs/image-viewer/image-viewer.component';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-transportor',
  templateUrl: './details-transportor.component.html',
  styleUrls: ['./details-transportor.component.css']
})
export class DetailsTransportorComponent implements OnInit {

  transporter: any = {}
  idTrans: string = '';
  env: any;

  statusDemandes: any = [];
  
  imageURL = 'https://www.motors.tn/magazine/wp-content/uploads/2021/04/E21-moteur-ISUZU.jpg.webp';
  viewerOpen = false;

  constructor(private route: ActivatedRoute,
    private transServices: TransportorsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )
    this.env = environment
    this.route.queryParams.subscribe((params) => {
      this.idTrans = params.transportor;
      this._fetchTransportor();
      // console.log(this.cmd);
    });
  }

  viewerOpenImage(src) {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(ImageViewerComponent, {
      data: {
        viewerOpen: true,
        imageURL: src,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }

  _fetchTransportor() {
    // console.log(cid);
    this.transServices.getOneTrans(this.idTrans).then((d) => {
    
      if (d) {
        console.log("dddddddddddddd")
        console.log(d)
        this.transporter = d;
      } else {
        alert('Aucune transporter valide');
        location.href = '/transportors';
      }
    });
  }

  validateTransportor() {
    this.transServices.validateTransportor(this.idTrans).then((data: any) => {
      console.log("dddddddddddddd")
      console.log(data)
    
      if (data && data.response && data.data) {
        // console.log("dddddddddddddd")
        // console.log(d)
        alert("Le transporteur est validé avec succé");
        this.transporter = data.data;
      } else {
        alert(data.msg);
        // location.href = '/transportors';
      }
    });
  }

  blockTransportor() {
    if(this.transporter!=null && this.transporter.is_blocked != null) {
      if(this.transporter.is_blocked == false) {
        this.transServices.blockedTansportor(this.idTrans).then((data: any) => {
          console.log("dddddddddddddd data")
          console.log(data)
          if (data && data.response && data.data) {
            this.transporter = data.data;
          } else {
            alert(data.msg);
          }
        });
      } else {
        this.transServices.deblockedTansportor(this.idTrans).then((data: any) => {
          console.log("dddddddddddddd")
          console.log(data)
          if (data && data.response && data.data) {
            this.transporter = data.data;
          } else {
            alert(data.msg);
          }
        });
      }
    }
  }

}
