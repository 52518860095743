<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Transporteurs
                <div class="page-title-subheading">La liste des transporteurs actifs.
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-md-6 col-xl-4" (click)="isValidTransportor()">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-check text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Transporteur validé</div>
                    <div class="widget-heading bg-danger">HORS LIGNE: {{nbrTransHorsLine}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{allTransValid.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="isNotValidTransportor()">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-refresh text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Transporteur non validé</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{allTransNotValid.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="listTransportors()">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-photo-gallery text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Tous les transporteurs</div>
                    <div class="widget-heading bg-warning">En attente de validation: {{nbrTransPending}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{nbrAllTrans}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Transporteurs
                <!-- <div class="btn-actions-pane-right" *ngIf="tagTransportor=='Transporteur validé'">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-success" (click)="onLineTrans()">EN LIGNE</button>
                        <button class="btn btn-danger" (click)="offLineTrans()">HORS LIGNE</button>
                    </div>
                </div>
                <div class="btn-actions-pane-right" *ngIf="tagTransportor=='Tous les transporteurs'">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-success" (click)="listTransportors()">ALL</button>
                        <button class="btn btn-danger" (click)="pendingTrans()">En attente</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Transporteur</th>
                            <!-- <th class="text-center">Commercial</th> -->
                            <th class="text-center">Téléphone</th>
                            <th class="text-center">Totale des courses</th>
                            <th class="text-center">Montant des courses fini</th>
                            <th class="text-center">Montant à payer</th>
                            <!-- <th class="text-center">Pick UP</th> -->
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let driver of allDrivers, let index = index" [class]="(driver.is_blocked==true) ? 'bg-tr-danger' : ''">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((driver?.user_transportor?.profile_user!=null && trans?.user_transportor?.profile_user!='') 
                                                    ? (driver?.user_transportor?.profile_user) : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                {{trans.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{driver?.user_transportor?.prenom_user}}
                                                {{driver?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{driver?.user_transportor?.email_user}}</div>
                                                <!-- <div class="widget-subheading opacity-7">
                                                    {{driver?._id}}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- <td class="text_center"><strong>{{trans?.commercial_code}}</strong></td> -->
                            <td class="text-center"><strong>{{driver?.user_transportor?.tel_user}}</strong></td>
                            <td class="text-center">
                                <div class="badge badge-danger" (click)="showDetailsDemandes(driver?.dmds, index)">
                                    {{driver?.dmds?.length}}
                                </div>
                            </td>
                            <td class="text-center"><strong>{{driver?.payment | number:'0.3'}} TND</strong></td>
                            <td class="text-center"><strong>{{(driver?.payment * 10) / 100 | number:'0.3'}} TND</strong></td>
                            
                            
                            <!-- <td class="text-center">
                                <b>{{element.client?.datePickUp}}</b>
                            </td> -->
                            
                            
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" [routerLink]="['/detail-transportor']"
                                            [queryParams]="{ transportor: driver._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details transporteur
                                        </button>
                                        
                                        
                                    </div>
                                </div>
                        
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>
