<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Dashboard
                <div class="page-title-subheading">Il s'agit d'un tableau de bord créé à l'aide
                    d'éléments et de composants intégrés.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
                            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                                class="btn-shadow mr-3 btn btn-dark">
                                <i class="fa fa-star"></i>
                            </button>
                            <div class="d-inline-block dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    class="btn-shadow dropdown-toggle btn btn-info">
                                    <span class="btn-icon-wrapper pr-2 opacity-7">
                                        <i class="fa fa-business-time fa-w-20"></i>
                                    </span>
                                    Buttons
                                </button>
                                <div tabindex="-1" role="menu" aria-hidden="true"
                                    class="dropdown-menu dropdown-menu-right">
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-inbox"></i>
                                                <span>
                                                    Inbox
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-book"></i>
                                                <span>
                                                    Book
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-picture"></i>
                                                <span>
                                                    Picture
                                                </span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                                <i class="nav-link-icon lnr-file-empty"></i>
                                                <span>
                                                    File Disabled
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['validated'] }">
        <div class="card mb-3 widget-content bg-warning">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En attente</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.validated?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['transporting_depot'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Au dépôt</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.transporting_depot?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['prepared'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Préparer</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.prepared?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['transporting_to', 'transporting_between', 'transporting', 'transported', 'arrived'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En cours</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.inProgress?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['delivered'], paied: false }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livré par le transporteur</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.deliveredUserTrack?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['delivered'], paied: true }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livré</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.delivered?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['payment_request'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En cours<br> de paiement</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.demandePayment?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['paied'] }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livré payés</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.paied?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
                    [queryParams]="{ status: ['returned'] }">
                        <div class="card mb-3 widget-content bg-warning">
                            <div class="widget-content-wrapper text-white">
                                <div class="widget-content-left mr-3">
                                    <div class="icon-wrapper rounded-circle">
                                        <div class="icon-wrapper-bg bg-primary"></div>
                                        <i class="pe-7s-home text-white"></i>
                                    </div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">Retour dépôt</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-white"><span>{{nbrCmdsReturned | async}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/returned-commandes']"
        [queryParams]="{ returned_by: 'returned_recu' }">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour reçu</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.returned_recu?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/returned-commandes']"
        [queryParams]="{ returned_by: 'return_request' }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En cours de retour</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.return_request?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/returned-commandes']"
        [queryParams]="{ returned_by: 'returned_exp' }">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour expéditeur</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.returned_exp?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/returned-commandes']"
        [queryParams]="{ returned_by: 'returned_client' }">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour client</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.returned_client?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">La liste des commandes actuelles
                <div class="btn-actions-pane-right">
                    <button type="button" id="PopoverCustomT-1"
                        class="btn btn-danger btn-sm">{{cmdList.length}}</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-hover">
                    <thead>
                        <tr>
                            <!-- <th class="text-center">#</th> -->
                            <th>Fournisseur</th>
                            <th class="text-left">Référence</th>
                            <!-- <th class="text-center">Date Création</th> -->
                            <th class="text-center">Destination</th>
                            <th class="text-center">En attente</th>
                            <th class="text-center">Au dépôt</th>
                            <th class="text-center">Préparer</th>
                            <th class="text-center">En cours<br>de transport</th>
                            <th class="text-center">Livré<br>par le<br>transporteur</th>
                            <th class="text-center">Demande<br>de<br>paiement</th>
                            <th class="text-center">Commentaire</th>
                            <th class="text-center">Affecté à</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">prévu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of cmdList, let index = index" [class]="cmd.planned.colorTime"
                            class="border-tr">
                            <!-- <td class="text-center text-muted">#345</td> -->
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{cmd.element.client_cmd.prenom_client}}
                                                                {{cmd.element.client_cmd.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd.element.client_cmd.company_name}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd.element.client_cmd.prenom_client}}
                                                {{cmd.element.client_cmd.nom_client}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text"><a (click)="goToNewTab(cmd.element._id, '/detail-cmd')">{{cmd.element.ref_facture_cmd}}</a></td>
                            <!-- <td class="text-center">
                                                {{cmd.element.date_creation_cmd | date:'d MMM y'}}<br>
                                                <b>{{cmd.element.date_creation_cmd | date:'HH:mm'}}</b>
                                            </td> -->
                            <td class="text-center">
                                <strong>{{cmd.element.destination_cmd}}</strong>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'validated'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.element.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'transporting_depot'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.pcollect_transport?.date_accept | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.pcollect_transport?.date_accept | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'prepared'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.prepared_by?.date_accept | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.prepared_by?.date_accept | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'transporting' || cmd.element.etat_cmd == 'transported' || cmd.element.etat_cmd == 'arrived'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.transport_topcollect?.date_accept | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.transport_topcollect?.date_accept | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'delivered'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.date_relivered_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.date_relivered_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'payment_request' || cmd.element.etat_cmd == 'returned'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.date_payment_request | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.date_payment_request | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <span class="badge badge-pill badge-danger"
                                    (click)="listComments(cmd)">{{cmd.element?.comments?.length}}</span>
                            </td>
                            <td class="text-center" style="color: red;">
                                <strong>{{cmd.element.transport_affect[cmd.element.transport_affect.length-1]?.transporter_id?.nom_prenom_tracking}}</strong>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    {{statusCmds[cmd.element.etat_cmd].label}}</div>
                            </td>
                            <td class="text-center">{{cmd.planned.plannedTime | date:'d MMM'}}<br>
                                <b>{{cmd.planned.plannedTime | date:'HH:mm'}}</b>
                            </td>
                            <!-- <td class="text-center">
                                                <button type="button" id="PopoverCustomT-1"
                                                    class="btn btn-primary btn-sm">Details</button>
                                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                <button class="btn-wide btn btn-success">Save</button>
                            </div> -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-lg-6">
        <!-- <div class="mb-3 card">
                            <div class="card-header-tab card-header-tab-animation card-header">
                                <div class="card-header-title">
                                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                                    Sales Report
                                </div>
                                <ul class="nav">
                                    <li class="nav-item"><a href="javascript:void(0);" class="active nav-link">Last</a>
                                    </li>
                                    <li class="nav-item"><a href="javascript:void(0);"
                                            class="nav-link second-tab-toggle">Current</a></li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="tabs-eg-77">
                                        <div class="card mb-3 widget-chart widget-chart2 text-left w-100">
                                            <div class="widget-chat-wrapper-outer">
                                                <div
                                                    class="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                                    <canvas id="canvas"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 class="text-muted text-uppercase font-size-md opacity-5 font-weight-normal">
                                            Top Authors</h6>
                                        <div class="scroll-area-sm">
                                            <div class="scrollbar-container">
                                                <ul
                                                    class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/9.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ella-Rose Henry</div>
                                                                    <div class="widget-subheading">Web Developer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>129</span>
                                                                        <small class="text-danger pl-2">
                                                                            <i class="fa fa-angle-down"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/5.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ruben Tillman</div>
                                                                    <div class="widget-subheading">UI Designer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>54</span>
                                                                        <small class="text-success pl-2">
                                                                            <i class="fa fa-angle-up"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/4.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Vinnie Wagstaff</div>
                                                                    <div class="widget-subheading">Java Programmer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>429</span>
                                                                        <small class="text-warning pl-2">
                                                                            <i class="fa fa-dot-circle"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/3.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ella-Rose Henry</div>
                                                                    <div class="widget-subheading">Web Developer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>129</span>
                                                                        <small class="text-danger pl-2">
                                                                            <i class="fa fa-angle-down"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/2.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ruben Tillman</div>
                                                                    <div class="widget-subheading">UI Designer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>54</span>
                                                                        <small class="text-success pl-2">
                                                                            <i class="fa fa-angle-up"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
        <div class="mb-3 card">
            <div class="card-header-tab card-header-tab-animation card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                    Mouvement livreur
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade show active">
                        <div class="scroll-area-sm">
                            <div class="scrollbar-container">
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let leader of leadersList">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <!-- <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/9.jpg" alt=""> -->
                                                    <ngx-avatar class="rounded-circle"
                                                        name="{{leader.element.nom_prenom_tracking}}">
                                                    </ngx-avatar>
                                                </div>
                                                <div class="widget-content-left" style="width: 45%;">
                                                    <div class="widget-heading">
                                                        {{leader.element.nom_prenom_tracking}}</div>
                                                    <div class="widget-subheading">
                                                        {{leader.element.numTel_tracking}}</div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-primary">
                                                        <div class="style-badge">
                                                            {{leader.transportingCmd}}</div>
                                                        En cours
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-success">
                                                        <div class="style-badge">{{leader.liveredCmd}}
                                                        </div>Livré
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-danger">
                                                        <div class="style-badge">{{leader.returningCmd}}
                                                        </div>Retour
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-6">
        <!-- <div class="mb-3 card">
                            <div class="card-header-tab card-header-tab-animation card-header">
                                <div class="card-header-title">
                                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                                    Sales Report
                                </div>
                                <ul class="nav">
                                    <li class="nav-item"><a href="javascript:void(0);" class="active nav-link">Last</a>
                                    </li>
                                    <li class="nav-item"><a href="javascript:void(0);"
                                            class="nav-link second-tab-toggle">Current</a></li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="tabs-eg-77">
                                        <div class="card mb-3 widget-chart widget-chart2 text-left w-100">
                                            <div class="widget-chat-wrapper-outer">
                                                <div
                                                    class="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                                    <canvas id="canvas"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 class="text-muted text-uppercase font-size-md opacity-5 font-weight-normal">
                                            Top Authors</h6>
                                        <div class="scroll-area-sm">
                                            <div class="scrollbar-container">
                                                <ul
                                                    class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/9.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ella-Rose Henry</div>
                                                                    <div class="widget-subheading">Web Developer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>129</span>
                                                                        <small class="text-danger pl-2">
                                                                            <i class="fa fa-angle-down"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/5.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ruben Tillman</div>
                                                                    <div class="widget-subheading">UI Designer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>54</span>
                                                                        <small class="text-success pl-2">
                                                                            <i class="fa fa-angle-up"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/4.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Vinnie Wagstaff</div>
                                                                    <div class="widget-subheading">Java Programmer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>429</span>
                                                                        <small class="text-warning pl-2">
                                                                            <i class="fa fa-dot-circle"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/3.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ella-Rose Henry</div>
                                                                    <div class="widget-subheading">Web Developer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>129</span>
                                                                        <small class="text-danger pl-2">
                                                                            <i class="fa fa-angle-down"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-3">
                                                                    <img width="42" class="rounded-circle"
                                                                        src="assets/images/avatars/2.jpg" alt="">
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading">Ruben Tillman</div>
                                                                    <div class="widget-subheading">UI Designer</div>
                                                                </div>
                                                                <div class="widget-content-right">
                                                                    <div class="font-size-xlg text-muted">
                                                                        <small class="opacity-5 pr-1">$</small>
                                                                        <span>54</span>
                                                                        <small class="text-success pl-2">
                                                                            <i class="fa fa-angle-up"></i>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
        <div class="mb-3 card">
            <div class="card-header-tab card-header-tab-animation card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                    État actuel des clients WASSALI
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade show active">
                        <div class="scroll-area-sm">
                            <div class="scrollbar-container">
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let element of clientsList, let index = index">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <ngx-avatar class="rounded-circle" name="{{element.client.prenom_client}}
                                                                {{element.client.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                                <div class="widget-content-left" style="width: 55%;">
                                                    <div class="widget-heading">
                                                        {{element.client.prenom_client}}
                                                        {{element.client.nom_client}}
                                                        ({{element.client.company_name}})</div>
                                                    <div class="widget-subheading">
                                                        {{element.client.numTel_client}}</div>
                                                </div>
                                                <div class="widget-content-right" *ngIf="element.hasDemande">
                                                    <div class="badge badge-primary">
                                                        <div class="style-badge"><i class="fa fa-check"></i></div>
                                                        Demande
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-warning">
                                                        <div class="style-badge">{{element.nbrPickUp}}
                                                        </div>Pick-up
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="row">
                    <div class="col-md-6 col-xl-4">
                        <div class="card mb-3 widget-content">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Total Orders</div>
                                        <div class="widget-subheading">Last year expenses</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <div class="widget-numbers text-success">1896</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="card mb-3 widget-content">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Products Sold</div>
                                        <div class="widget-subheading">Revenue streams</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <div class="widget-numbers text-warning">$3M</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="card mb-3 widget-content">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Followers</div>
                                        <div class="widget-subheading">People Interested</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <div class="widget-numbers text-danger">45,9%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-xl-none d-lg-block col-md-6 col-xl-4">
                        <div class="card mb-3 widget-content">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Income</div>
                                        <div class="widget-subheading">Expected totals</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <div class="widget-numbers text-focus">$147</div>
                                    </div>
                                </div>
                                <div class="widget-progress-wrapper">
                                    <div class="progress-bar-sm progress-bar-animated-alt progress">
                                        <div class="progress-bar bg-info" role="progressbar" aria-valuenow="54"
                                            aria-valuemin="0" aria-valuemax="100" style="width: 54%;"></div>
                                    </div>
                                    <div class="progress-sub-label">
                                        <div class="sub-label-left">Expenses</div>
                                        <div class="sub-label-right">100%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

<!-- <div class="row">
                    <div class="col-md-6 col-lg-3">
                        <div class="card-shadow-danger mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-content">
                                <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left pr-2 fsize-1">
                                            <div class="widget-numbers mt-0 fsize-3 text-danger">71%</div>
                                        </div>
                                        <div class="widget-content-right w-100">
                                            <div class="progress-bar-xs progress">
                                                <div class="progress-bar bg-danger" role="progressbar"
                                                    aria-valuenow="71" aria-valuemin="0" aria-valuemax="100"
                                                    style="width: 71%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content-left fsize-1">
                                        <div class="text-muted opacity-6">Income Target</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card-shadow-success mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-content">
                                <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left pr-2 fsize-1">
                                            <div class="widget-numbers mt-0 fsize-3 text-success">54%</div>
                                        </div>
                                        <div class="widget-content-right w-100">
                                            <div class="progress-bar-xs progress">
                                                <div class="progress-bar bg-success" role="progressbar"
                                                    aria-valuenow="54" aria-valuemin="0" aria-valuemax="100"
                                                    style="width: 54%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content-left fsize-1">
                                        <div class="text-muted opacity-6">Expenses Target</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card-shadow-warning mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-content">
                                <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left pr-2 fsize-1">
                                            <div class="widget-numbers mt-0 fsize-3 text-warning">32%</div>
                                        </div>
                                        <div class="widget-content-right w-100">
                                            <div class="progress-bar-xs progress">
                                                <div class="progress-bar bg-warning" role="progressbar"
                                                    aria-valuenow="32" aria-valuemin="0" aria-valuemax="100"
                                                    style="width: 32%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content-left fsize-1">
                                        <div class="text-muted opacity-6">Spendings Target</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card-shadow-info mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-content">
                                <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left pr-2 fsize-1">
                                            <div class="widget-numbers mt-0 fsize-3 text-info">89%</div>
                                        </div>
                                        <div class="widget-content-right w-100">
                                            <div class="progress-bar-xs progress">
                                                <div class="progress-bar bg-info" role="progressbar" aria-valuenow="89"
                                                    aria-valuemin="0" aria-valuemax="100" style="width: 89%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content-left fsize-1">
                                        <div class="text-muted opacity-6">Totals Target</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->