import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {

  constructor(private clientsService: ClientsService) { }

  ngOnInit(): void {
  }

  async addClient(firstName, lastName, companyName, username, password, adress, numTel) {
    await this.clientsService.subscribe(firstName.value, lastName.value, 
      companyName.value, username.value,
      password.value, adress.value, numTel.value)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          location.href = '/clients';
          //this.sacsList = res;
        }
      });
  }

}
