import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-planified-demandes',
  templateUrl: './planified-demandes.component.html',
  styleUrls: ['./planified-demandes.component.css']
})
export class PlanifiedDemandesComponent implements OnInit {

  statusDemandes: any = [];
  allDemandes: any = [];
  
  subscription: Subscription;
  intervalId: number = null;

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )
    this.listPlanifiedDemandes()
    // const source = interval(1000);
    // this.subscription = source.subscribe(val => {
    //   this.listPlanifiedDemandes()
    // });
  }

  async listPlanifiedDemandes() {
    await this.transServices.getPlanifiedDemandes().then((res: any) => {
      // console.log("fetchPendingPlanifiedDemandes");
      // console.log(res);
      if (res) {
        this.allDemandes = res;
      }
    });
  }
}
