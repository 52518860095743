import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TravelersService } from 'src/app/services/travelers/travelers.service';

@Component({
  selector: 'app-one-reservation',
  templateUrl: './one-reservation.component.html',
  styleUrls: ['./one-reservation.component.css']
})
export class OneReservationComponent implements OnInit {

  idTrip: string = ""
  trip: any = {}

  constructor(private route: ActivatedRoute,
    private travelersService: TravelersService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idTrip = params.trip;
      this._fetchTrip();
    });
  }

  _fetchTrip() {
    this.travelersService.getOneTrip(this.idTrip).then((d) => {
      // console.log("trip")
      // console.log(d)
      if (d) {
        this.trip = d;
      }
    });
  }
}
