<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Transporteurs
                <div class="page-title-subheading">La liste des transporteurs enregistrés.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?" type="keyword"
                                class="form-control" #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-6 col-xl-4" (click)="isValidTransportor()">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-check text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Transporteur validé</div>
                    <div class="widget-heading bg-danger">HORS LIGNE: {{nbrTransHorsLine}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{allTransValid.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="isNotValidTransportor()">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-refresh text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Transporteur non validé</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{allTransNotValid.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="listTransportors()">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-photo-gallery text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Tous les transporteurs</div>
                    <div class="widget-heading bg-warning">En attente de validation: {{nbrTransPending}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{nbrAllTrans}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">{{tagTransportor}}
                <div class="btn-actions-pane-right" *ngIf="tagTransportor=='Transporteur validé'">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-success" (click)="onLineTrans()">EN LIGNE</button>
                        <button class="btn btn-danger" (click)="offLineTrans()">HORS LIGNE</button>
                    </div>
                </div>
                <div class="btn-actions-pane-right" *ngIf="tagTransportor=='Tous les transporteurs'">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-success" (click)="listTransportors()">ALL</button>
                        <button class="btn btn-danger" (click)="pendingTrans()">En attente</button>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Transporteur</th>
                            <!-- <th class="text-center">Commercial</th> -->
                            <th class="text-center">Téléphone</th>
                            <th class="text-center">Numéro<br>carte identité</th>
                            <th class="text-center">Dernière<br>connexion</th>
                            <th class="text-center">Type<br>du<br>véhicule</th>
                            <th class="text-center">Validé</th>
                            <th class="text-center">Etat</th>
                            <!-- <th class="text-center">Pick UP</th> -->
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trans of allTrans, let index = index" [class]="(trans.is_blocked==true) ? 'bg-tr-danger' : ''">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((trans?.user_transportor?.profile_user!=null && trans?.user_transportor?.profile_user!='') 
                                                    ? (trans?.user_transportor?.profile_user) : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                {{trans.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{trans?.user_transportor?.prenom_user}}
                                                {{trans?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{trans?.user_transportor?.email_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{trans?._id}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- <td class="text_center"><strong>{{trans?.commercial_code}}</strong></td> -->
                            <td class="text-center"><strong>{{trans?.user_transportor?.tel_user}}</strong></td>
                            <td class="text-center">{{trans?.id_card?.num_id_card}}</td>
                            <td class="text-center">{{trans?.user_transportor?.lastcnx_user | date:'MMM d, y'}}</td>
                            <td class="text-center"><strong>{{trans?.info_vehicule?.type_camion}}</strong></td>
                            <td class="text-center">
                                <div [class]="(trans?.isValidate) ? 'badge badge-success' : 'badge badge-danger'">
                                    {{(trans?.isValidate) ? 'Validé' : 'Non validé'}}
                                </div><br>
                                {{(trans?.preteToValidate!=null) ? ((trans?.preteToValidate == true) ? 'En attente de validation' : "N'est pas prét") : ''}}
                            </td>
                            <!-- <td class="text-center">
                                <b>{{element.client?.datePickUp}}</b>
                            </td> -->
                            <td class="text-center">
                                <div [class]="(trans.onLine) ? 'badge badge-success' : 'badge badge-danger'">
                                    {{(trans.onLine) ? 'En ligne' : 'Hors ligne'}}</div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="mr-2 btn-icon btn-icon-only btn" [class]="(trans.draft != null && trans.draft == true) ? 'btn-outline-success' : 'btn-outline-danger'"  (click)="draftTransportor(trans._id)"><i
                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" [routerLink]="['/detail-transportor']"
                                            [queryParams]="{ transportor: trans._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details transporteur
                                        </button>
                                        <!-- <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery == null"
                                            [routerLink]="['/frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Ajouter frais</button>
                                        <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery != null"
                                            [routerLink]="['/edit-frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Modifier frais</button>
                                        <button type="button" tabindex="0" [routerLink]="['/compte-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Compte client</button>
                                        <button type="button" tabindex="0" (click)="pickUpCollector(element.client._id)"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Affecter le pick-up</button> -->
                                    </div>
                                </div>
                                <!-- <button [routerLink]="['/detail-cmds-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details</button> -->

                                <!-- <button *ngIf="element.fraisClient == null"
                                    [routerLink]="['/frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                <!-- <button *ngIf="element.fraisClient != null"
                                    [routerLink]="['/edit-frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->

                                <!-- <button [routerLink]="['/compte-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->

                                <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                    id="PopoverCustomT-1"
                                    class="btn btn-warning btn-sm">Affecter</button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>