<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Détails de notre parc
                <div class="page-title-subheading">La liste des vehicule dans notre parc.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <div class="d-inline-block dropdown"> -->
            <!-- <div class="position-relative form-group col-12">
                    <legend for="client" class="">Clients</legend>
                    <select class="form-control selectric"
                        (change)="selectClient($event)">
                        <option value="Selectionnez le client">
                            Selectionnez le client</option>
                        <option [value]="cl.client._id" *ngFor="let cl of clientsList">
                            {{cl.client.prenom_client + " " + cl.client.nom_client}}</option>
                    </select>
                    <button type="submit" (click)="getClient(keyword)" class="btn btn-primary">Submit</button>
                </div> -->
            <div>
                <form class="form-inline">
                    <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                        <select class="form-control selectric select-size" (change)="selectCamion($event)">
                            <option value="Tous les véhicules">
                                Tous les véhicules</option>
                            <option [value]="veh.name" *ngFor="let veh of vehList">
                                {{veh.name}}
                                <img src="{{veh.image}}">
                            </option>
                        </select>
                    </div>
                    <button type="submit" (click)="getDetailParc()" class="btn btn-primary">Envoyer</button>
                </form>
            </div>

            <!-- <div class="search-wrapper">
                    <div class="input-holder">
                        <form class="needs-validation" action="" method="post" onsubmit="return false;"
                            (onsubmit)="listCmdsClient(keyword);"> 
                        <input type="text" id="keyword" name="keyword" class="search-input"
                            placeholder="Type to search" #keyword ngModel>

                        <button type="button" (click)="getClient(keyword)"
                            class="search-icon"><span></span></button>
                         </form> 
                    </div>
                    <button class="close"></button>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>
<div class="row" *ngIf="vehicule!='Tous les véhicules' && allTransportors.length>0">
    <div class="col-md-6 col-xl-4" (click)="onLineTransportor()">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-check text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">EN LIGNE</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{allTransOnLine.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="offLineTransportor()">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-refresh text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">HORS LIGNE</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{allTransOffLine.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="getAllDetailParc()">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-photo-gallery text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Tous les transporteurs</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{nbrAllTrans}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="allTransportors.length>0">
    <div class="col-md-12">
        <div class="main-card mb-3">
            <div class="card-header">
                <div class="float-left">Filtre</div>
                <div class="float-right" style="margin-left: auto;">
                    <div>
                        <form class="form-inline" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterCmds();">
                            <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                                <select class="form-control selectric select-size" (change)="selectChangeVille($event)">
                                    <option *ngFor="let ville of villes" [value]="ville.id">
                                        {{ville.label}}</option>
                                </select>
                            </div>
                            <button type="submit" (click)="filterCmds()"
                                class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search" tabindex="4">
                                <i class="fas fa-search"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-3" *ngFor="let trans of allTrans">
        <div class="card mb-3 widget-chart text-white card-border" [class]="(trans?.is_blocked==true) ? 'bg-tr-danger' : ''">
            <div class="photo-wrapper photo-vehicule">
                <div class="icon-wrapper-bg bg-white opacity-1"></div><img width="300"
                    src="{{env.vehiculeTransportorAssetsBaseURL + trans?.info_vehicule?.photo_vehicule}}" alt="product">
            </div>
            <div [class]="(trans.onLine) ? 'badge badge-success' : 'badge badge-danger'">
                {{(trans.onLine) ? 'En ligne' : 'Hors ligne'}}</div>
            <div class="widget-heading text-black">{{trans?.user_transportor?.prenom_user}}
                {{trans?.user_transportor?.nom_user}}</div>
            <div *ngIf="trans?.dataPlace?.city!=null && trans?.dataPlace?.administrativeLevels?.level1long!=null" class="widget-heading text-red">{{trans?.dataPlace?.city + " - " +
                trans?.dataPlace?.administrativeLevels?.level1long}}</div>
            <div class="widget-heading fsize-1 text-info">Numéro de téléphone: <span
                    class="badge badge-pill badge-dark">{{trans?.user_transportor?.tel_user}}</span> </div>
            <div class="widget-heading text-black">{{trans?.info_vehicule?.immatricule.part1}} Tun
                {{trans?.info_vehicule?.immatricule.part2}}</div>
            <div class="widget-description text-success" (click)="goToNewTab(trans?._id, '/detail-transportor')">
                <button class="mb-2 mr-2 btn btn-danger">Détail transporteur
                    <!-- <span class="badge badge-pill badge-light">{{leader.transportingCmd}}</span> -->
                </button>
            </div>
        </div>
    </div>
</div>