import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import io from 'socket.io-client';
import { TeamsService } from '../teams-wassali/teams.service';

@Injectable({
  providedIn: 'root'
})
export class TransportorsService {

  socket = io.connect("https://logistic.marketbey.tn/", { transports: ['polling'] })

  constructor(private http: HttpClient,
    private router: Router,
    private teamService: TeamsService,) { }
    
  async getPaymentDriver() {
    return new Promise((slv) => {
      this.http.post(environment.apiPaymentDriver, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          console.log("Cmd error")
          console.log(error)
          slv(false);
        }
      );
    });
  }

    async addAppel(demande) {
      var user: any = await this.teamService.getUserInfos()
      return new Promise((slv) => {
        this.http.post(environment.apiAddAppel, {
          demande: demande,
          idUser: user._id,
          nameUser: user.prenom_user + " " + user.nom_user,
          typeUser: "admin"
        }).subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            slv(false);
          }
        );
      });
    }

  addComment(demande, idUser, nameUser, typeUser, comment) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddComment, {
        demande,
        idUser,
        nameUser,
        typeUser,
        comment
      }).subscribe(
        (data: any) => {
          // console.log(data);
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async blockedTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiBlockedTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async draftTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDraftTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async deblockedTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDeblockedTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allCommercials() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllCommercials, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allTypeCamion() {
    return await new Promise((slv) => {
      this.http.post(environment.apiTypeCamion, {}).subscribe(
        (data: any) => {
          this.socket.emit('demandes', 'Hello there from Angular.');
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allServices() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetServices, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  addCommercial(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddCommercial, formData).subscribe(
        (res: any) => {
          //console.log(JSON.stringify(data));
          if (res) {
            if (res.response) {
              slv(res.response);
            } else {
              slv(res);
            }
          }
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  addSociety(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddSociety, formData).subscribe(
        (res: any) => {
          //console.log(JSON.stringify(data));
          if (res) {
            if (res.response) {
              slv(res.response);
            } else {
              slv(res);
            }
          }
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allSocieties() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllSocieties, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getLabeledStatusDemande() {
    return new Promise((slv) => {
      this.http.post(environment.apiStatusDemandes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getMarkerTransportors(name, numtel, vehicule) {
    return await new Promise((slv) => {
      this.http.post(environment.apiFilterMarkersTansportors, {
        name,
        numtel,
        vehicule,
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async clientsWassali(page, name, numero, society?, datein?, datefin?) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllClientsWassali, {
        page,
        name: name,
        numtel: numero,
        startDate: datein,
        society,
        endDate: datefin,
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async demandesTransportors(page, status, name?, numero?, datein?, datefin?, service?, vehicule?, planified?, dateinPlan?, datefinPlan?) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllDemandes, {
        page,
        status: status,
        name: name,
        numtel: numero,
        startDate: datein,
        endDate: datefin,
        service,
        vehicule,
        planified,
        startDatePlan: dateinPlan,
        endDatePlan: datefinPlan,
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getOneDemande(demandeId) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneDemande, { "idDemande": demandeId }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getPendingDemandes() {
    return await new Promise((slv) => {
      this.http.post(environment.apiPendingDemandes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getPlanifiedDemandes() {
    return await new Promise((slv) => {
      this.http.post(environment.apiPlanifiedDemandes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getTransportors() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTansportors, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getTransportorsByTypeVehicule(typeCamion) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTansportorsByVehicule, {
        "type_camion": typeCamion
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getOneTrans(idTrans) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneTansportor, { "idTrans": idTrans }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  validateTransportor(idTrans) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiValidateTansportor, { "iddriver": idTrans }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data) {
            slv(data);
          }// else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getReturnsValid(status) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllReturnByStatus, { "status": status }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getPendingEmptyReturn() {
    return await new Promise((slv) => {
      this.http.post(environment.apiPendingEmptyReturn, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async updateEmptyReturn(idReturn, status) {
    return await new Promise((slv) => {
      this.http.post(environment.apiUpdateEmptyReturn, { "idEmptyReturn": idReturn, "newstatus": status }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  goToNewTab(idDriver, route) {
    const queryParams = {
      transportor: idDriver
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  async getSponsores() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllSponsores, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  goToNewTabDemande(idDemande, route) {
    const queryParams = {
      demande: idDemande
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

}
