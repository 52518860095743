import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersTrackingService {

  constructor(private http: HttpClient,
    private router: Router,) { }

  goToNewTab(idLivreur, route) {
    const queryParams = {
      userTracking: idLivreur
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  getOneUserTracking(id) {
    return new Promise((slv) => {
      this.http.post(environment.apiGetOneUserTracking + "/" + id, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  subscribe(name, username,
    password, profile, numTel, numTel2, isResponsable?, superieur?) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddUsersTracking, {
        name: name,
        username: username,
        password: password,
        profil: profile,
        numTel: numTel,
        numTel2: numTel2,
        pcollect: null,
        responsable: isResponsable,
        superieur: superieur
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaa")
          // console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getResponsables() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetUsersTracking, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getCollectors() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetCollector, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getPreparers() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetPreparers, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getLouagistes() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetLouagistes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getLouagistesByCity(city) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetLouagistesByCity, {city: city}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getLouagiste(idLouag) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetOneLouagiste, {louagiste: idLouag}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async cmdsLivredByTransporter(idUser) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsLivredByTransporter + "/" + idUser, {}).subscribe(
        (data: any) => {
          // console.log("cmdsLivredByTransporter")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async cmdsReturnedByTransporter(idUser) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsReturnedByTransporter + "/" + idUser, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allCmdsTrackingLivredReturnedAndInProgress(idUser) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress,
        { user: idUser }).subscribe(
          (data: any) => {
            // console.log("apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress")
            // console.log(data)
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  subscribeLouage(firstName, lastName, username,
    password, isClimatise, numTel1, numTel2, startingLouage, arrivedLouage,
    serieLouagePartOne, serieLouagePartTwo) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddUsersLouage, {
        firstName: firstName,
        lastName: lastName,
        userName: username,
        password: password,
        "profil": "LOUAGISTE",
        numTel: numTel1,
        numTel2: numTel2,
        isClimatise: isClimatise,
        startingLouage: startingLouage,
        arrivedLouage: arrivedLouage,
        serieLouagePartOne: serieLouagePartOne,
        serieLouagePartTwo: serieLouagePartTwo
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaa")
          // console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getArrest() {
    return new Promise((slv) => {
      this.http.post(environment.apiGetArrest, {
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaa")
          // console.log(data)
          if (data && data.response) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  statisticsTransportors(startDate, endDate, transportor) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsTransportor, {
        transportor,
        startDate,
        endDate
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
