import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeamsService } from 'src/app/services/teams-wassali/teams.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-demande',
  templateUrl: './details-demande.component.html',
  styleUrls: ['./details-demande.component.css']
})
export class DetailsDemandeComponent implements OnInit {

  user: any = null

  demande: any = {}
  demandeID: string = '';
  env: any;

  statusDemandes: any = [];

  mapLoaded: boolean;
  map: google.maps.Map;
  center: google.maps.LatLngLiteral;

  source: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 12
  }

  ds: google.maps.DirectionsService;
  dr: google.maps.DirectionsRenderer;

  constructor(private route: ActivatedRoute,
    private teamService: TeamsService,
    private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    this.getInfosUser()

    this.env = environment
    this.route.queryParams.subscribe(async (params) => {
      this.demandeID = params.demande;
      this._fetchDemande();
      // console.log(this.cmd);
    });

  }

  async getInfosUser() {
    await this.teamService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.user = res;
      }
    });
  }
  addAppel(idDemande) {
    if (this.demande.appel_by == null)
      this.transServices.addAppel(idDemande).then((data: any) => {
        // console.log("dddddddddddddd")
        // console.log(data)

        if (data) {
          this.demande = data;
        }
      });
  }

  addComment(comment) {
    if (comment.value != "")
      this.transServices.addComment(this.demande._id, this.user._id,
        this.user.prenom_user + " " + this.user.nom_user, "admin", comment.value.trim()).then((data: any) => {
          // console.log("dddddddddddddd")
          // console.log(data)

          if (data) {
            this.demande = data;
            comment.value = ""
          }
        });
  }

  _initMap() {
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });

    navigator.geolocation.getCurrentPosition(position => {

      this.source = {
        lat: this.demande.user_location.lat,
        lng: this.demande.user_location.lng
      };

      this.destination = {
        lat: this.demande.user_destination.lat,
        lng: this.demande.user_destination.lng
      };

      // initialize the map container
      this.map = new google.maps.Map(document.getElementById('map-canvas'), {
        ...this.options,
        center: this.source
      });

      this.map.addListener('tilesloaded', () => {
        this.mapLoaded = true;
      });

      // adding a marker
      var markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          url: './../../../../assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      var destinationMarker = new google.maps.Marker({
        position: this.destination,
        icon: {
          url: './../../../../assets/images/destination_map_marker2.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      this.setRoutePolyline();
    });
  }

  setRoutePolyline() {
    let request = {
      origin: this.source,
      destination: this.destination,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        map: this.map
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
      }
    })
  }

  async _fetchDemande() {
    await this.transServices.getOneDemande(this.demandeID).then((d) => {
      console.log("ddddddddddddddddd")
      console.log(d)
      if (d) {
        this.demande = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/demandes-transportors';
      }
    });

    this._initMap();
  }

  goToNewTab(idDriver, route) {
    this.transServices.goToNewTab(idDriver, route)
  }

}
