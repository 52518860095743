import { Component, OnInit } from '@angular/core';
import { SacsService } from 'src/app/services/sacs/sacs.service';

@Component({
  selector: 'app-add-sac',
  templateUrl: './add-sac.component.html',
  styleUrls: ['./add-sac.component.css']
})
export class AddSacComponent implements OnInit {

  villes = ["Selectionnez une ville",
    { "ville": 'Ariana', "color": "#850606" },
    { "ville": 'Béja', "color": "BurlyWood" },
    { "ville": 'Ben Arous', "color": "SandyBrown" },
    { "ville": 'Bizerte', "color": "Brown" },
    { "ville": 'Gabès', "color": "Crimson" },
    { "ville": 'Gafsa', "color": "Green" },
    { "ville": 'Jendouba', "color": "LightSeaGreen" },
    { "ville": 'Kairouan', "color": "Teal" },
    { "ville": 'Kasserine', "color": "SteelBlue" },
    { "ville": 'Kébili', "color": "DimGray" },
    { "ville": 'Kef', "color": "DarkSlateGray" },
    { "ville": 'Mahdia', "color": "MidnightBlue" },
    { "ville": 'Manouba', "color": "Fuchsia" },
    { "ville": 'Médenine', "color": "PeachPuff" },
    { "ville": 'Monastir', "color": "Gold" },
    { "ville": 'Nabeul', "color": "OrangeRed" },
    { "ville": 'Sfax', "color": "red" },
    { "ville": 'Sidi Bouzid', "color": "DarkSeaGreen" },
    { "ville": 'Sousse', "color": "Purple" },
    { "ville": 'Tataouine', "color": "RosyBrown" },
    { "ville": 'Tozeur', "color": "Wheat" },
    { "ville": 'Tunis', "color": "Grey" },
    { "ville": 'Zaghouan', "color": "Coral" }];
  selectedVille: any = {};

  video: File = null;
  image: File = null;
  filesToUpload: Array<File> = [];

  constructor(private sacsServices: SacsService,) { }

  ngOnInit(): void {
  }

  // selectChangeVille(event: any) {
  //   //update the ui
  //   this.selectedVille = event.target.value;
  // }


  selectVille(destination: any) {
    // console.log("event.target.value")
    // console.log(this.selectedVille)
    // this.selectedVille = event.target.value;
  }

  async addSac() {
    // console.log("addSac : ")
    if (this.selectedVille != '') {
      await this.sacsServices.addSac(this.selectedVille)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            location.href = '/sacs';
            //this.sacsList = res;
          }
        });
    }
  }

  async upload(title) {
    const formData: any = new FormData();

    // const files: Array<File> = this.filesToUpload;
    // for (let i = 0; i < files.length; i++) {
    //   formData.append("uploads[]", files[i], files[i]['name']);
    // }

    if (this.video != null) {
      formData.append("video", this.video, this.video['name']);
    }
    // formData.append("thumbnail", this.image, this.image['name']);
    formData.append("title", title.value);
    await this.sacsServices.uploadVideo(formData)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)

      });
  }

  handleVideoInput(event) {
    const fileToUpload = <Array<File>>event.target.files;
    this.video = fileToUpload[0]
  }
  // handleImageInput(event) {
  //   const fileToUpload = <Array<File>>event.target.files;
  //   this.image = fileToUpload[0]
  // }
  handleImagesInput(event) {
    const newFfilesToUpload = <Array<File>>event.target.files;
    // this.selectedFiles = event.target.files;
    for (let i = 0; i < newFfilesToUpload.length; i++) {
      this.filesToUpload.push(newFfilesToUpload[i]);
    }
  }

}
