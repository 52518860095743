<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-add-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Utilisateur
                <!-- <div class="page-title-subheading">Ajouter un nouveau utilisateur (TRANSPORTEUR /
                    PREPARATEUR / LOUAGISTE).
                </div> -->
                <div class="page-title-subheading">Ajouter un nouveau utilisateur (LOUAGISTE).
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post" onsubmit="return false;"
                (onsubmit)="addUser(name, username, password, numTel, numTel2);">
                <div class="form-row">
                    <div [class]="isProfileIncludes('LOUAGISTE') ? 'col-3' : 'col-md-6'">
                        <div class="position-relative form-group">
                            <legend for="name" class="">Prénom d'utilisateur</legend>
                            <input name="name" id="name" type="text" class="form-control" #name ngModel>
                        </div>
                    </div>

                    <div class="col-3" *ngIf="isProfileIncludes('LOUAGISTE')">
                        <div class="position-relative form-group">
                            <legend for="lastName" class="">Nom d'utilisateur</legend>
                            <input name="lastName" type="text" class="form-control"
                                [(ngModel)]="lastName">
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="position-relative form-group">
                            <legend for="username" class="">Email</legend>
                            <input name="username" id="username" type="text" class="form-control"
                                #username ngModel>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="position-relative form-group">
                            <legend for="password" class="">Password</legend>
                            <input name="password" id="password" type="password" class="form-control"
                                #password ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="position-relative form-group">
                            <legend for="confirmPassword" class="">Confirmer password</legend>
                            <input name="confirmPassword" id="confirmPassword" type="password"
                                class="form-control" #confirmPassword ngModel>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="position-relative form-group">
                            <legend for="password" class="">Profiles</legend>
                            <select class="form-control" multiple [(ngModel)]="selectedProfiles" name="profile">
                                <option [value]="profile" *ngFor="let profile of profiles">
                                    {{profile}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="position-relative form-group">
                            <legend for="numTel" class="">Numéro téléphone</legend>
                            <input name="numTel" id="numTel" type="number" class="form-control" #numTel
                                ngModel name="numTel">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="position-relative form-group">
                            <legend for="numTel2" class="">Numéro téléphone 2</legend>
                            <input name="numTel2" id="numTel2" type="number" class="form-control"
                                #numTel2 ngModel name="numTel2">
                        </div>
                    </div>
                </div>
                <div *ngIf="isProfileIncludes('LOUAGISTE')">
                    <div class="form-row">
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <legend for="startingLouage" class="">Point d'entrée</legend>
                                <select class="form-control" [(ngModel)]="startingLouage"
                                    name="startingLouage">
                                    <option [value]="arrest.arrest" *ngFor="let arrest of arrests">
                                        {{arrest.arrest}} - {{arrest.gouvernorat}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <legend for="arrivedLouage" class="">Point d'arrivée</legend>
                                <select class="form-control" [(ngModel)]="arrivedLouage"
                                    name="arrivedLouage">
                                    <option [value]="arrest.arrest" *ngFor="let arrest of arrests">
                                        {{arrest.arrest}} - {{arrest.gouvernorat}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-5">
                            <div class="position-relative form-group">
                                <legend for="password" class="">Climatisé</legend>
                                <div class="position-relative form-check">
                                    <label class="form-check-label">
                                        <input name="responsable" type="radio" checked [value]="true"
                                            class="form-check-input" (change)="setResponsable($event)">
                                        Oui
                                    </label>
                                    <br>
                                    <label class="form-check-label">
                                        <input name="responsable" type="radio" [value]="false"
                                            class="form-check-input" (change)="setResponsable($event)">
                                        Non
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="position-relative form-group">
                                <legend for="part1" class="">Part 1</legend>
                                <input name="part1" id="part1" type="number" class="form-control"
                                    [(ngModel)]="part1">
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="position-relative form-group">
                                <legend class="text-center">Tunis</legend>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="position-relative form-group">
                                <legend for="part2" class="">Part 2</legend>
                                <input name="part2" id="part2" type="number" class="form-control"
                                    [(ngModel)]="part2">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="position-relative row form-group" *ngIf="isResponsable==false">
                        <legend for="exampleSelect" class="col-sm-2 col-form-label">Supérieur</legend>
                        <div class="col-sm-10">
                            <select class="form-control" (change)="selectSuperieur($event)">
                                <option>Selectionnez le supérieur</option>
                                <option value="{{superieur.element._id}}"
                                    *ngFor="let superieur of leadersList" #superieur ngModel>
                                    {{superieur.element.nom_prenom_tracking}}</option>
                            </select>
                        </div>
                    </div> -->
                </div>
                <div *ngIf="isProfileIncludes('TRANSPORTEUR')">
                    <div class="position-relative row form-group">
                        <legend for="exampleText" class="col-sm-4 col-form-label">Responsable</legend>
                        <div class="col-sm-3">
                            <div class="position-relative form-check">
                                <label class="form-check-label">
                                    <input name="responsable" type="radio" checked [value]="true"
                                        class="form-check-input" (change)="setResponsable($event)"> Oui
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="position-relative form-check">
                                <label class="form-check-label">
                                    <input name="responsable" type="radio" [value]="false"
                                        class="form-check-input" (change)="setResponsable($event)"> Non
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="position-relative row form-group" *ngIf="isResponsable==false">
                        <legend for="exampleSelect" class="col-sm-2 col-form-label">Supérieur</legend>
                        <div class="col-sm-10">
                            <select class="form-control" (change)="selectSuperieur($event)">
                                <option>Selectionnez le supérieur</option>
                                <option value="{{superieur.element._id}}"
                                    *ngFor="let superieur of leadersList" #superieur ngModel>
                                    {{superieur.element.nom_prenom_tracking}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit"
                            (click)="addUser(name, username, password, numTel, numTel2)"
                            class="btn btn-primary">Ajouter utilisateur</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>