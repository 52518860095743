<!-- <i class="material-icons" style="font-size: 100px;color: #ff0000">info</i> -->
<div mat-dialog-content>
    <!-- <h1 style="text-align:center"><strong></strong></h1> -->
    <h2 style="text-align:center;font-size: x-large; color: rgb(170, 32, 32);">Détail des demandes :
    </h2>
    <div class="div-form">
        <div class="form-group">
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="data.demandes.length === 0">
                    <h5 class="text-center">Aucune commande créé</h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="data.demandes.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de la<br>demande</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Service</th>
                            <!-- <th class="text-center">Propositions</th> -->
                            <th class="text-center">Etat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let demande of data.demandes, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                ? demande?.user_transportor?.profile_user : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                            {{demande.user_transportor.nom_user}}">
                                            </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading"
                                                (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-transportor')">
                                                {{demande?.user_transportor?.prenom_user}}
                                                {{demande?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{demande?.user_transportor?.email_user}}</div>
                                            <div class="widget-subheading">
                                                {{demande?.user_transportor?.tel_user}}</div>
                                            <div
                                                [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                            <div class="widget-subheading"
                                                *ngIf="demande?.flux != null && demande?.flux != ''">
                                                <b>→ {{demande?.flux}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{demande?.action_date_demande | date:'d MMM y HH:mm'}}</td>
                            <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                <strong>{{demande?.user_location?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">
                                <strong
                                    (click)="goToNewTab(demande?.type_vehicule, '/parc-transportors')">{{demande?.type_vehicule}}</strong><br>
                                <strong>{{demande?.type_service}}</strong><br>
                                <strong>{{demande?.type_products}}</strong><br>
                                <strong style="color: tomato;">{{demande?.acceptedBy?.proposition}}
                                    DT</strong>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusDemandes[demande?.etat_demande]?.color}}">
                                    {{statusDemandes[demande?.etat_demande]?.label}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <!-- <button style="margin-right: 15px;" [mat-dialog-close]="false" class="btn btn-info">NON</button> -->

    <button style="margin-right: 15px;" [mat-dialog-close]="true" class="btn btn-danger">OUI</button>
</div>