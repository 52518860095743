<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Clients wassali
                <div class="page-title-subheading">La liste des clients de transport.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <h2>Nombre de client: {{totalClients}}</h2>
            <!-- <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?" type="keyword"
                                class="form-control" #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterClients(name, numero, datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="name" class="">Client</legend>
                                        <input name="name" id="name" type="text" class="form-control" #name ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="number" class="form-control" #numero
                                            ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Société</legend>
                                        <select id="society" name="society"
                                            class="form-control selectric select-size" [(ngModel)]="society">
                                            <option value="all">Tous</option>
                                            <option value="true">OUI</option>
                                            <option value="false">NON</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            #datein ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            #datefin ngModel>
                                    </div>
                                </div>
                                <button type="submit" (click)="filterClients(name, numero, datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Clients
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Clients</th>
                            <th class="text-center">Dernière<br>connexion</th>
                            <th class="text-center">Demandes<br>en cours</th>
                            <th class="text-center">Demandes<br>acceptéé</th>
                            <th class="text-center">Demandes<br>terminée</th>
                            <th class="text-center">Demandes<br>annulée</th>
                            <th class="text-center">Total<br>des<br>demandes</th>
                            <!-- <th class="text-center">Destination</th>
                            <th class="text-center">Service</th>
                            <th class="text-center">Propositions</th>
                            <th class="text-center">Etat</th> -->
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr *ngFor="let client of allClients, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                        ((client?.infos?.profile_user!=null && client?.infos?.profile_user!='') 
                                                        ? (client?.infos?.profile_user) : 'profile.jpg')}}" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">{{client?.infos?.prenom_user}}
                                                    {{client?.infos?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{client?.infos?.email_user}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{client?.infos?.tel_user}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{client?.infos?.lastcnx_user | date:'MMM d, y'}}</td>
                                <td class="text-center">
                                    <div class="badge badge-warning">
                                        {{filterDemande(client?.totalDemandes, ['created'])}}</div>
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-info">
                                        {{filterDemande(client?.totalDemandes, ['accepted', 'started', 'arrived'])}}</div>
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-success">
                                        {{filterDemande(client?.totalDemandes, ['finish'])}}</div>
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-danger">
                                        {{filterDemande(client?.totalDemandes, ['canceled'])}}</div>
                                </td>
                                <td class="text-center">{{client?.totalDemandes?.length}}</td>                                
                                <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <!-- <button type="button" [routerLink]="['/details-demande']"
                                                [queryParams]="{ demande: demande._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details demande
                                            </button> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

            <div class="d-block text-center card-footer" *ngIf="allClients?.length > 0 && pages?.length > 1">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="previousPage(name, numero, datein, datefin)">
                                <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                Précédent</a>
                        </li>

                        <div *ngFor="let p of pagesDisplaied">
                            <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                <a *ngIf="currentPage!==p" class="page-link"
                                    (click)="selectPage(p, name, numero, datein, datefin)">{{p+1}}</a>

                                <span *ngIf="currentPage===p" class="page-link">
                                    {{p+1}}
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                        </div>

                        <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="nextPage(name, numero, datein, datefin)">Suivant
                                <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>