// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// var baseURLAPI = 'http://localhost:3005/';
var baseURLAPI = 'https://logistic.marketbey.tn/api/v0/';

var assetsBaseURL = "https://assets.marketbey.tn/Wassali/";

export const environment = {
  production: false,

  apiUpload: baseURLAPI + 'sacs/upload-video',

  // API COMMERCIAL TRANSPORTORS
  apiAddCommercial: baseURLAPI + 'transportor/add-commercial',
  apiAllCommercials: baseURLAPI + 'transportor/all-commercials',

  // API SOCIETY TRANSPORTORS
  apiAddSociety: baseURLAPI + 'transportor/add-society',
  apiAllSocieties: baseURLAPI + 'transportor/all-societies',

  // API BUSINESS ACCOUNT
  apiAllBusinessAccounts: baseURLAPI + 'societies/all-business-accounts',
  apiAddBusinessAccount: baseURLAPI + 'societies/add-business-account',

  // API TRANSPORTORS
  apiAllTansportors: baseURLAPI + 'transportor/driver-transportors',
  apiOneTansportor: baseURLAPI + 'transportor/details-transportor',
  apiValidateTansportor: baseURLAPI + 'transportor/validate-transportor',
  apiMarkersTansportors: baseURLAPI + 'transportor/get-markers-driver',
  apiFilterMarkersTansportors: baseURLAPI + 'transportor/filter-markers-driver',
  apiStatusDemandes: baseURLAPI + 'transportor/liststatus',
  apiAllClientsWassali: baseURLAPI + 'transportor/clients-wassali',
  apiAllDemandes: baseURLAPI + 'transportor/demandes',
  apiOneDemande: baseURLAPI + 'transportor/get-demande',
  apiTypeCamion: baseURLAPI + 'transportor/type-camions',
  apiGetServices: baseURLAPI + 'transportor/get-services',
  apiAllTansportorsByVehicule: baseURLAPI + 'transportor/parc-transportors',
  apiBlockedTansportor: baseURLAPI + 'transportor/block-transportor',
  apiDeblockedTansportor: baseURLAPI + 'transportor/deblock-transportor',
  apiDraftTansportor: baseURLAPI + 'transportor/make-on-draft',
  apiAllSponsores: baseURLAPI + 'sponsores/all-sponsore',
  apiPendingDemandes: baseURLAPI + 'transportor/pending-demandes',
  apiPlanifiedDemandes: baseURLAPI + 'transportor/planified-demandes',
  apiAddComment: baseURLAPI + 'transportor/add-comment',
  apiAddAppel: baseURLAPI + 'transportor/add-appel',
  apiPaymentDriver: baseURLAPI + 'transportor/payment-drivers',

  // API RETURN TRANSPORTORS
  apiAllReturnByStatus: baseURLAPI + 'transportor/empty-return-by-status',
  apiPendingEmptyReturn: baseURLAPI + 'transportor/pending-empty-return',
  apiUpdateEmptyReturn: baseURLAPI + 'transportor/update-empty-return',

  // API SACS
  apiAddSac: baseURLAPI + 'sacs/add-sac',
  apiAllSacs: baseURLAPI + 'sacs/list-sacs',
  apiAllSacsByVille: baseURLAPI + 'sacs/sacs-ville',
  apiOneSac: baseURLAPI + 'sacs/sac',
  apiEmptySac: baseURLAPI + 'sacs/empty-sac',
  apiGetSacsFromLouagiste: baseURLAPI + 'sacs/sacs-louagiste',
  apiAffectSacToLouagiste: baseURLAPI + 'sacs/affect-sac',

  // API USERS TRACKING
  apiGetOneUserTracking: baseURLAPI + 'tracking/get-users-tracking',
  apiAddUsersTracking: baseURLAPI + 'tracking/subscribe',
  apiAddUsersLouage: baseURLAPI + 'louage/subscribe',
  apiGetUsersTracking: baseURLAPI + 'tracking/user-responsable',
  apiGetCollector: baseURLAPI + 'tracking/list-collectors',
  apiGetPreparers: baseURLAPI + 'tracking/preparers',
  apiGetLouagistes: baseURLAPI + 'louage/louagistes',
  apiGetLouagistesByCity: baseURLAPI + 'louage/louagistes-city',
  apiGetOneLouagiste: baseURLAPI + 'louage/one-louagiste',
  apiCmdsLivredByTransporter: baseURLAPI + 'tracking/cmds-livred',
  apiCmdsReturnedByTransporter: baseURLAPI + 'tracking/cmds-returned',
  apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress: baseURLAPI + 'tracking/details-cmds-tracking',

  // API CLIENTS
  apiAddClient: baseURLAPI + 'client/add-client',
  apiAddFraisClient: baseURLAPI + 'client/add-frais-client',
  apiGetFraisClient: baseURLAPI + 'client/frais-client',
  apiAllClients: baseURLAPI + 'client/list-clients',
  apiOneClient: baseURLAPI + 'client/one-client',
  apiOneAccountClient: baseURLAPI + 'client/account-client',
  apiSearchClient: baseURLAPI + 'client/search-client',
  apiAllCmdsByStatus: baseURLAPI + 'cmds/cmds-all-status',
  apiAccountsClient: baseURLAPI + "client/accounts-client",

  apiAffectCollector: baseURLAPI + 'client/affect-pickup-collector',

  apiCmdsNotPaied: baseURLAPI + "client/cmds-not-paied",
  apiCmdsNotReturned: baseURLAPI + "client/cmds-not-returned",
  apiCmdsNotPaiedAndNotReturned: baseURLAPI + "client/details-cmds-client",

  apiAddAccountClient: baseURLAPI + "client/add-account-client",

  apiDemandesClient: baseURLAPI + "client/demandes-client",

  // API CMDS
  apiAllCmds: baseURLAPI + 'cmds/list-cmds',
  apiStatisticsFluxCmds: baseURLAPI + 'cmds/statistics-flux-cmds',
  apiAllCmdsByClient: baseURLAPI + 'cmds/list-cmds-client',
  apiStatsCmds: baseURLAPI + 'cmds/statistics-cmds',
  apiOneCmd: baseURLAPI + 'cmds/cmd',
  apiAllCmdsByVille: baseURLAPI + 'cmds/cmds-ville',//////
  apiStatus: baseURLAPI + 'cmds/liststatus',
  apiSearchCmd: baseURLAPI + 'cmds/search',
  apiUpdateCmdStatus: baseURLAPI + 'cmds/updatecmdstatus',
  apiAllCmdsByStatusAndUser: baseURLAPI + 'cmds/cmds-status-transport',
  apiAllCmdsByStatusAndClient: baseURLAPI + 'cmds/cmds-status-client',
  apiFilterCmds: baseURLAPI + 'cmds/filter-cmds',
  apiAllComments: baseURLAPI + 'cmds/all-comments',

  apiPaiedCmdsByTransporter: baseURLAPI + 'cmds/paied-cmds-transporter',
  apiValidateReturnedCmds: baseURLAPI + 'cmds/validate-return-cmds',

  // user travelers
  apiAllTravelers: baseURLAPI + 'traveler-user/travelers',
  apiAllTrips: baseURLAPI + 'louage/all_trips',
  apiOneTrip: baseURLAPI + 'louage/info_trip',
  apiStatsTransportor: baseURLAPI + 'tracking/statistics-transportor',

  // arrest
  apiGetArrest: baseURLAPI + 'traveler/arrests',

  // AUTH Teams WASSALI
  apiUsersAuth: baseURLAPI + "teams-ws/auth",
  apiInfosUser: baseURLAPI + "teams-ws/infos",

  // URL ASSETS IMAGE
  transportorUsersAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/users-trans/",
  driverTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-licence/",
  cardTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-card/",
  vehiculeTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-vehicule/",
  patentTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-patent/",
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
