import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private http: HttpClient) { }

  isConnected() {
    var token = localStorage.getItem('token-ws')
    // console.log("token : " + token)
    if (token)
      return true;

    return false
  }

  getUserInfos() {
    var token = localStorage.getItem('token-ws')

    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);
        // console.log("decoded")
        // console.log(decoded)
        this.http.post(environment.apiInfosUser, { "teamid": decoded["id"] }).subscribe((res: any) => {
          // console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          // console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });

  }

  saveUserCreds(usercreds) {
    localStorage.setItem('token-ws', JSON.stringify(usercreds.token));
  }

  login(creds) {
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        // console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(false);
          }
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem('token-ws');
  }
}
