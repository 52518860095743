import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamsService } from 'src/app/services/teams-wassali/teams.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-add-comments',
  templateUrl: './add-comments.component.html',
  styleUrls: ['./add-comments.component.css']
})
export class AddCommentsComponent implements OnInit {

  user: any = null

  constructor(public dialogRef: MatDialogRef<AddCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private teamService: TeamsService,
    private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.getInfosUser()
  }

  addComment(comment) {
    if(comment.value != "")
    this.transServices.addComment(this.data.demande._id, this.user._id,
      this.user.prenom_user + " " + this.user.nom_user, "admin", comment.value.trim()).then((data: any) => {
        // console.log("dddddddddddddd")
        // console.log(data)

        if (data) {
          this.data.demande = data;
          comment.value = ""
        }
      });
  }

  async getInfosUser() {
    await this.teamService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.user = res;
      }
    });
  }

}
