import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parc-transportors',
  templateUrl: './parc-transportors.component.html',
  styleUrls: ['./parc-transportors.component.css']
})
export class ParcTransportorsComponent implements OnInit {

  vehList: any = []
  vehicule: string = "Tous les véhicules"

  allTransportors: any = []
  allTrans: any = []
  allTransOnLine: any = [];
  allTransOffLine: any = [];
  nbrAllTrans: number = 0
  env: any;

  villes = [
    {
      id: 1,
      label: "ALL",
      secondLabel: "ALL"
    },
    {
      id: 2,
      label: "Ariana",
      secondLabel: "Gouvernorat de l'Ariana"
    },
    {
      id: 3,
      label: 'Béja',
      secondLabel: "Gouvernorat de Béja",
      thirdLabel: 'Beja',
      fourLabel: "Gouvernorat de Beja"
    },
    {
      id: 4,
      label: 'Ben Arous',
      secondLabel: "Gouvernorat de Ben Arous"
    },
    {
      id: 5,
      label: 'Bizerte',
      secondLabel: "Gouvernorat de Bizerte"
    },
    {
      id: 6,
      label: 'Gabès',
      secondLabel: "Gouvernorat de Gabès",
      thirdLabel: 'Gabes',
      fourLabel: "Gouvernorat de Gabes"
    },
    {
      id: 7,
      label: 'Gafsa',
      secondLabel: "Gouvernorat de Gafsa"
    },
    {
      id: 8,
      label: 'Jendouba',
      secondLabel: "Gouvernorat de Jendouba"
    },
    {
      id: 9,
      label: 'Kairouan',
      secondLabel: "Gouvernorat de Kairouan"
    },
    {
      id: 10,
      label: 'Kasserine',
      secondLabel: "Gouvernorat de Kasserine"
    },
    {
      id: 11,
      label: 'Kébili',
      secondLabel: "Gouvernorat de Kébili",
      thirdLabel: 'Kebili',
      fourLabel: "Gouvernorat de Kebili"
    },
    {
      id: 12,
      label: 'Kef',
      secondLabel: "Gouvernorat de Kef"
    },
    {
      id: 13,
      label: 'Mahdia',
      secondLabel: "Gouvernorat de Mahdia"
    },
    {
      id: 14,
      label: 'Manouba',
      secondLabel: "Gouvernorat de Manouba"
    },
    {
      id: 15,
      label: 'Médenine',
      secondLabel: "Gouvernorat de Médenine",
      thirdLabel: 'Medenine',
      fourLabel: "Gouvernorat de Medenine"
    },
    {
      id: 16,
      label: 'Monastir',
      secondLabel: "Gouvernorat de Monastir"
    },
    {
      id: 17,
      label: 'Nabeul',
      secondLabel: "Gouvernorat de Nabeul"
    },
    {
      id: 18,
      label: 'Sfax',
      secondLabel: "Gouvernorat de Sfax"
    },
    {
      id: 19,
      label: 'Sidi Bouzid',
      secondLabel: "Gouvernorat de Sidi Bouzid"
    },
    {
      id: 20,
      label: 'Siliana',
      secondLabel: "Gouvernorat de Siliana"
    },
    {
      id: 21,
      label: 'Sousse',
      secondLabel: "Gouvernorat de Sousse"
    },
    {
      id: 22,
      label: 'Tataouine',
      secondLabel: "Gouvernorat de Tataouine"
    },
    {
      id: 23,
      label: 'Tozeur',
      secondLabel: "Gouvernorat de Tozeur"
    },
    {
      id: 24,
      label: 'Tunis',
      secondLabel: "Gouvernorat de Tunis"
    },
    {
      id: 25,
      label: 'Zaghouan',
      secondLabel: "Gouvernorat de Zaghouan"
    }
  ];
  selectedVille: any = null;

  constructor(private route: ActivatedRoute,
    private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.env = environment
    this.listVehicule()

    this.route.queryParams.subscribe((params) => {
      if (params.transportor != null) {
        this.vehicule = params.transportor;
        this.getDetailParc();
      }
      console.log("this.vehicule");
      console.log(this.vehicule);
    });
  }

  async listVehicule() {
    await this.transServices.allTypeCamion().then((res: any) => {
      // console.log("listVehicule : ")
      // console.log(res)
      if (res) {
        this.vehList = res;
      }
    });
  }

  selectCamion(event: any) {
    this.vehicule = event.target.value;
    if (event.target.value == "Tous les véhicules") {
      this.allTransportors = []
      this.allTrans = []
      this.allTransOnLine = [];
      this.allTransOffLine = [];
      this.nbrAllTrans = 0
    } 
  }

  async getDetailParc() {
    if (this.vehicule != "Tous les véhicules") {
      await this.transServices.getTransportorsByTypeVehicule(this.vehicule).then((res: any) => {
        console.log("getDetailParc : ")
        console.log(res)
        if (res) {
          this.allTransportors = res
          this.allTrans = res;
          this.nbrAllTrans = this.allTrans.length
          this.allTransOnLine = this.filterTransportor(true)
          this.allTransOffLine = this.filterTransportor(false)
        }
      });
    }
  }

  getAllDetailParc() {
    this.allTrans = this.allTransportors
  }

  filterTransportor(isOnLine) {
    return this.allTrans.filter(trans => trans.onLine == isOnLine);
  }

  onLineTransportor() {
    this.allTrans = this.allTransOnLine
  }

  offLineTransportor() {
    this.allTrans = this.allTransOffLine
  }

  goToNewTab(idDriver, route) {
    this.transServices.goToNewTab(idDriver, route)
  }

  selectChangeVille(event: any) {
    console.log("this.event.target.value")
    console.log(event.target.value)
    if (event.target.value == 1) {
      this.selectedVille = null;
    } else {
      var ville = this.villes.find(val => { return val.id == event.target.value })
      console.log("villeeeeeeeeee")
      console.log(ville)
      this.selectedVille = ville;
    }
  }

  filterCmds() {
    console.log("this.selectedVille")
    console.log(this.selectedVille)
    if(this.selectedVille != null) {
      this.allTrans = this.allTransportors.filter
        (trans => (trans?.dataPlace?.administrativeLevels?.level1long == this.selectedVille.label
                  || trans?.dataPlace?.administrativeLevels?.level1long == this.selectedVille.secondLabel 
                  || (this.selectedVille.thirdLabel != null && trans?.dataPlace?.administrativeLevels?.level1long == this.selectedVille.thirdLabel)
                  || (this.selectedVille.fourLabel != null && trans?.dataPlace?.administrativeLevels?.level1long == this.selectedVille.fourLabel)));

      console.log("this.allTransportors : ")
      console.log(this.allTransportors)
    } else {
      this.allTrans = this.allTransportors
    }
  }

}
