import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PickupCollectorComponent } from 'src/app/components/shared/dialogs/pickup-collector/pickup-collector.component';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  clientsList: Observable<Array<any>>;

  constructor(private clientsService: ClientsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.listClients()
  }

  async listClients() {
    await this.clientsService.getClients().then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
      }
    });
  }

  async getClient(keyword) {
    await this.clientsService.searchClient(keyword.value).then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
      }
    });
    keyword.value = ""
  }


  async pickUpCollector(idClient) {
    // console.log("amineeeeeeeeee")
    const confirmDialog = this.dialog.open(PickupCollectorComponent, {
      data: {
        hourPickUp: null,
        collector: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data)
        // console.log("idSac : ")
        // console.log(idSac)
        this.clientsService.affecteCollecteur(idClient, data.collector, data.hourPickUp).then((res: any) => {
          // console.log("pickUpCollector : ");
          // console.log(res);
          if (res && !res.response) {
            alert("L'affectation est success")
            this.listClients()
          }
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        // this.listSacs();
      }
    });

  }
}
