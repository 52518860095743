import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DisplayDemandesComponent } from 'src/app/components/shared/dialogs/display-demandes/display-demandes.component';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';


@Component({
  selector: 'app-compte-transportor',
  templateUrl: './compte-transportor.component.html',
  styleUrls: ['./compte-transportor.component.css']
})
export class CompteTransportorComponent implements OnInit {

  allDrivers : any = [];

  constructor(private transServices: TransportorsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    
    this.listDrivers()
  }

  async listDrivers() {
    
    await this.transServices.getPaymentDriver().then((res: any) => {
      if (res) {
        this.allDrivers = res
      }
    });
  }

  async showDetailsDemandes(demandes, index) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // this.selectedVirement = index
    const confirmDialog = this.dialog.open(DisplayDemandesComponent, {
      data: {
        demandes: demandes,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });

  }

}
