import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandesService {

  constructor(private http: HttpClient,
    private router: Router) { }

  getOneCMD(ref) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmd + "/" + ref, { }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getLabeledStatus() {
    // ["pending", "validated", "preparing", "submited", "transported", "arrived", "delivered", "returned", "refused", "canceled"],
    return new Promise((slv) => {
      this.http.post(environment.apiStatus, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsUserByStatus(idUser, status) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_cmd: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusAndUser, {
        transport: idUser,
        status: listStatus
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsByStatus(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatus, {
        client: idClient
      }).subscribe(
        (data: any) => {
          // console.log("dataaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsByVille(ville) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByVille, {
        ville: ville
      }).subscribe(
        (data: any) => {
          // console.log("dataaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  updateCmdStatus(id, status) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiUpdateCmdStatus, {
          cmd: id,
          newstatus: status,
        })
        .subscribe(
          (data: any) => {
            // console.log("data update status : ");
            // console.log(JSON.stringify(data));
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  paiedCmdByTransporter(idCmd) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiPaiedCmdsByTransporter, {
          cmd: idCmd
        })
        .subscribe(
          (data: any) => {
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  validateReturnedCmdsByTransporter(idCmd, returned_by) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiValidateReturnedCmds, {
          cmd: idCmd,
          returned_by: returned_by
        })
        .subscribe(
          (data: any) => {
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  searchCmd(idClient, keyword) {
    return new Promise((slv) => {
      this.http.post(environment.apiSearchCmd, {
        client: idClient,
        keyword: keyword
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsClientByStatus(page, idClient, status, isPaied, returnedBy) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_cmd: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusAndClient, {
        page,
        client: idClient,
        status: listStatus,
        paied: isPaied,
        returnedBy: returnedBy
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  filterCmds(page, ref, status, ville, numero, datein, datefin) {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiFilterCmds, {
          page,
          ref: ref,
          status: status,
          ville: ville,
          numtel: numero,
          startDate: datein,
          endDate: datefin
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  allComments() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllComments, {
      }).subscribe(
        (data: any) => {
          // console.log(data);
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  statisticsCmds(client, startDate, endDate, ville) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsCmds, {
        client,
        startDate,
        endDate,
        ville
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async statisticFluxCmds() {
    return await new Promise((slv) => {
      this.http.post(environment.apiStatisticsFluxCmds, { }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  goToNewTab(idCmd, route) {
    const queryParams = {
      cmd: idCmd
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

}
