import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-status-commandes',
  templateUrl: './status-commandes.component.html',
  styleUrls: ['./status-commandes.component.css']
})
export class StatusCommandesComponent implements OnInit {

  statusCmds: Array<string> = []
  isPaied: boolean = null
  listCmds: any = []

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];
  
  statusCmd: any = []

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.statusCmds = params.status;
      this.isPaied = params.paied
      // console.log("params.status")
      // console.log(params.status)
      // console.log("params.paied")
      // console.log(params.paied)
      this.listCmdsClient(this.statusCmds, this.isPaied)
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  includes(array) {
    for(var i=0; i<array.length; i++) {
      if(this.statusCmds.includes(array[i])) {
        return true
      }
    }
    return false
  }

  async listCmdsClient(status, isPaied) {
    await this.cmdsService.allCmdsClientByStatus(this.currentPage, null, [status], isPaied, null).then((res: any) => {
      // console.log("res listCmdsClient")
      // console.log(res)
      if (res) {
        this.listCmds = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
        this.splicePages()
      }
    });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if(this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if(this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if(this.currentPage >= 5 && this.currentPage < this.pages.length-5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage-4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length-10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  previousPage() {
    this.currentPage--
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  selectPage(page) {
    this.currentPage = page
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

}
