<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commandes
                <div class="page-title-subheading">Bon d'achat.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <!-- <div class="card-header">Commandes
                            </div> -->
            <div class="table-responsive" id="commandePrete" #commandePrete>
                <div class="float-right" style="margin: 20px 100px;">
                    <div><b>Date : </b>{{nowDate | date:'d MMM y'}}</div>
                </div>
                <table class="table table-striped">
                    <tr>
                        <th>QrCode</th>
                        <th>Fournisseur</th>
                        <th>Reference</th>
                        <!-- <th>Date Création</th> -->
                        <th>Nom Client</th>
                        <th>Téléphone</th>
                        <th class="text-center">Adresse</th>
                        <th>Total Commande</th>
                        <th>Frais de livraison</th>

                    </tr>
                    <tr *ngFor="let cmd of listCmds">
                        <td>
                            <qrcode print [width]="100" usesvg="true" [elementType]="'svg'" [qrdata]="cmd.qr_code"
                                [errorCorrectionLevel]="'M'"></qrcode>
                        </td>
                        <td>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left mr-3">
                                        <div class="widget-content-left">
                                            <ngx-avatar class="rounded-circle" name="{{cmd.client_cmd.prenom_client}}
                                                        {{cmd.client_cmd.nom_client}}">
                                            </ngx-avatar>
                                        </div>
                                    </div>
                                    <div class="widget-content-left flex2">
                                        <div class="widget-heading">
                                            {{cmd.client_cmd.company_name}}
                                        </div>
                                        <div class="widget-subheading opacity-7">
                                            {{cmd.client_cmd.prenom_client}}
                                            {{cmd.client_cmd.nom_client}}</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><b>{{cmd.ref_facture_cmd}}</b>
                        </td>
                        <!-- <td>
                                        {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}
                                      </td> -->
                        <td>
                            <div class="d-inline-block ml-1">{{cmd.user_destination.nom_destination}}</div>
                        </td>
                        <td>{{cmd.user_destination.numtel_destination}}</td>
                        <td class="text-center">
                            {{cmd.user_destination.adresse_destination}} <br /> <strong>{{cmd.destination_cmd}}</strong>
                        </td>
                        <td>{{cmd.total_ca_cmd+cmd.frais_livraison |
                            number:'0.3'}} TND</td>
                        <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->
                        <td>
                            <div>5 TND </div>
                        </td>
                    </tr>
                    <!-- <tr>
                                  <td>Frais de livraison: </td>
                                  <td> </td>
                                </tr>
                                <tr>
                                  <td>Montant total</td>
                                  <td>{{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison}} TND</td>
                                </tr> -->

                </table>

            </div>

            <!-- <div class="table-responsive" id="commandePrete" #commandePrete>
                                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Qrcode</th>
                                            <th class="text-left">Référence</th>
                                            <th class="text-center">Nom Client & Téléphone</th>
                                            <th class="text-center">Ville</th>
                                            <th class="text-center">Total Commande</th>
                                            <th class="text-center">Frais de livraison</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                <tr *ngFor="let cmd of listCmds">
                                                    <qrcode print [width]="100" usesvg="true" [elementType]="'svg'"
                                                        [qrdata]="cmd.qr_code" [errorCorrectionLevel]="'M'"></qrcode>
                                                    <td class="text">{{cmd.ref_facture_cmd}}</td>
                                                    <td class="text-center">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left flex2">
                                                                    <div class="widget-heading">
                                                                        {{cmd.user_destination.nom_destination}}</div>
                                                                    <div class="widget-subheading opacity-7">
                                                                        {{cmd.user_destination.numtel_destination}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">{{cmd.user_destination.adresse_destination}}
                                                        <strong>{{cmd.destination_cmd}}</strong>
                                                    </td>
                                                    <td class="text-center">{{cmd.total_ca_cmd}} DT</td>
                                                    <td class="text-center">5
                                                    </td>
                                                </tr>
                                    </tbody>
                                </table>
                            </div> -->



            <div class="position-relative row form-check">
                <div class="col-sm-10 offset-sm-10">
                    <button type="submit" class="btn btn-warning" (click)="generatePDF()"><i class="pe-7s-print">
                            imprimer</i></button>
                </div>
            </div>
        </div>
    </div>
</div>