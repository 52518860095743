<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-magic-wand icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Codes de parrainage
                <div class="page-title-subheading">La liste des codes de parrainage.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Codes de parrainage

            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateur</th>
                            <th class="text-center">Code<br>de parrainage</th>
                            <th class="text-center">Date<br>de<br>création</th>
                            <th class="text-center">Compte</th>
                            <th class="text-center">Bonus</th>
                            <th class="text-center">Parrainé</th>
                            <th class="text-center">Parrainer par</th>
                            <th class="text-center">Date<br>d'expiration</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trans of allSponsors, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((trans?.user?.profile_user!=null && trans?.user?.profile_user!='') 
                                                    ? (trans?.user?.profile_user) : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                {{trans.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{trans?.user?.prenom_user}}
                                                {{trans?.user?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{trans?.user?.email_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{trans?.user?.tel_user}}</div>
                                            <div
                                                [class]="(trans?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                {{(trans?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text_center"><strong>{{trans?.code}}</strong></td>
                            <td class="text-center">{{trans?.creation_date_code | date:'MMM d, y'}}</td>
                            <td class="text-center"><strong>{{trans?.balance}}</strong></td>
                            <td class="text-center">{{(trans?.sponsored_is_used) ? "OUI" : "NON"}}</td>
                            <td class="text-center">{{trans?.bonus}}</td>
                            <td>
                                <div *ngIf="trans?.is_sponsored == true">
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((trans?.sponsored_by?.profile_user!=null && trans?.sponsored_by?.profile_user!='') 
                                                    ? (trans?.sponsored_by?.profile_user) : 'profile.jpg')}}" alt="">
                                                        <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                            </ngx-avatar> -->
                                                    </div>
                                                    <div class="widget-content-left">
                                                        <div class="widget-heading">
                                                            {{trans?.sponsored_by?.prenom_user}}
                                                            {{trans?.sponsored_by?.nom_user}}
                                                        </div>
                                                        <div class="widget-subheading">
                                                            {{trans?.sponsored_by?.tel_user}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td class="text-center">{{trans?.expired_date | date:'MMM d, y'}}</td>
                            <td class="text-center">
                                <div [class]="(trans?.etat_code) ? 'badge badge-success' : 'badge badge-danger'">
                                    {{(trans?.etat_code) ? 'Validé' : 'Expiré'}}</div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <!-- <button type="button" [routerLink]="['/detail-transportor']"
                                            [queryParams]="{ transportor: trans._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details transporteur
                                        </button>
                                        <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery == null"
                                            [routerLink]="['/frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Ajouter frais</button>
                                        <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery != null"
                                            [routerLink]="['/edit-frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Modifier frais</button>
                                        <button type="button" tabindex="0" [routerLink]="['/compte-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Compte client</button>
                                        <button type="button" tabindex="0" (click)="pickUpCollector(element.client._id)"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Affecter le pick-up</button> -->
                                    </div>
                                </div>
                                <!-- <button [routerLink]="['/detail-cmds-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details</button> -->

                                <!-- <button *ngIf="element.fraisClient == null"
                                    [routerLink]="['/frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                <!-- <button *ngIf="element.fraisClient != null"
                                    [routerLink]="['/edit-frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->

                                <!-- <button [routerLink]="['/compte-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->

                                <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                    id="PopoverCustomT-1"
                                    class="btn btn-warning btn-sm">Affecter</button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>