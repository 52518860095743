import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TravelersService } from 'src/app/services/travelers/travelers.service';

@Component({
  selector: 'app-users-travelers',
  templateUrl: './users-travelers.component.html',
  styleUrls: ['./users-travelers.component.css']
})
export class UsersTravelersComponent implements OnInit {

  travelersList: Observable<Array<any>>;

  constructor(private travelersService: TravelersService) { }

  ngOnInit(): void {
    this.listTravelers()
  }

  async listTravelers() {
    await this.travelersService.getTravelers().then((res: any) => {
      if (res) {
        this.travelersList = res;
      }
    });
  }

}
