<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Transporteurs
                <div class="page-title-subheading">This is an example dashboard created using build-in
                    elements and components.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngFor="let leader of leadersList">
    <div class="col-md-3">
        <div class="card mb-3 bg-premium-dark widget-chart text-white card-border">
            <div class="photo-wrapper rounded-circle">
                <div class="icon-wrapper-bg bg-white opacity-1"></div><img class="rounded-circle"
                    width="300" src="assets/images/avatars/9.jpg" alt="product">
            </div>
            <div class="widget-heading">Transporteur: {{leader.element.nom_prenom_tracking}} </div>
            <div class="widget-heading fsize-1 text-info">En cours de transport: <span
                class="badge badge-pill badge-light">{{leader.transportingCmd}}</span> </div>
            <div class="widget-description text-success" [routerLink]="['/commandes-tracking-user']"
                [queryParams]="{ idUser: leader.element._id }">
                <button class="mb-2 mr-2 btn btn-danger">Détail commandes
                    <!-- <span class="badge badge-pill badge-light">{{leader.transportingCmd}}</span> -->
                    </button>
            </div>
        </div>
    </div>
    <div class="col-md-9" *ngIf="leader.teams.length>0">
        <div class="main-card mb-3 card">
            <div class="card-header">L'équipe - {{leader.teams.length}} membre actif :
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Name</th>
                            <th class="text-center">Numéro tel</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of leader.teams, let index = index">
                            <td class="text-center text-muted">{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle"
                                                    name="{{user.nom_prenom_tracking}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{user.nom_prenom_tracking}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{user.username_tracking}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">26596037</td>
                            <td class="text-center">
                                <button type="button" id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details commandes</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>