<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-note2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commercial
                <div class="page-title-subheading">Ajouter un nouveau Commercial.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;">
                        <div class="row form-group">
                            <div class="col-12">
                                <div class="form-group">
                                    <legend for="code" class="">Code commercial</legend>
                                    <input name="code" id="code" type="text" class="form-control" #code ngModel>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="code.value==''">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="form-group">
                                    <legend for="firstName" class="">Prénom</legend>
                                    <input name="firstName" id="firstName" type="text" class="form-control" #firstName
                                        ngModel>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="firstName.value==''">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <legend for="lastName" class="">Nom</legend>
                                    <input name="lastName" id="lastName" type="text" class="form-control" #lastName
                                        ngModel>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="lastName.value==''">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="Neon Neon-theme-dragdropbox">
                                        <input style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; 
                    left: 0px; margin-right: auto; margin-left: auto;" name="file" id="file" #file accept="image/*"
                                            (change)="handleFileInput($event.target.files)" type="file">
                                        <div class="Neon-input-dragDrop">
                                            <div class="Neon-input-inner">
                                                <div class="Neon-input-icon" *ngIf="imageUrl==''">
                                                    <i class="fa fa-file-image-o"></i>
                                                </div>
                                                <div class="Neon-input-icon" *ngIf="imageUrl!=''">
                                                    <img [src]="imageUrl" style="width:100px">
                                                </div>
                                                <div class="Neon-input-text">
                                                    <h3>Drag&amp;Drop files here</h3> <span
                                                        style="display:inline-block; margin: 5px 0">or</span>
                                                </div><a class="Neon-input-choose-btn blue">Browse
                                                    Files</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="imageUrl==''">Ajouter
                                        la photo de profile
                                    </mat-error> -->
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12 form-group">
                                        <legend for="username" class="">Email</legend>
                                        <input name="username" id="username" type="text" class="form-control" #username
                                            ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="username.value=='' 
                                                || (username.value!='' && !username.value.includes('@'))">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>

                                    <div class="col-12 form-group">
                                        <legend for="numTel" class="">Numéro Tél</legend>
                                        <input name="numTel" id="numTel" type="number" class="form-control" #numTel
                                            ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="numTel.value=='' 
                                            || (numTel.value!='' && numTel.value.length!=8)">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="addClient(firstName, lastName, companyName, username, password, adress, numTel)"
                            class="btn btn-primary">Ajouter Client</button>
                    </div>
                </div> -->

                        <div class="form-group">
                            <button type="submit" (click)="addCommercial(code, firstName, lastName, username, numTel)"
                                class="btn btn-primary btn-lg btn-block">
                                Ajouter un commercial
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>