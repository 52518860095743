<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Demandes en cours
                <div class="page-title-subheading">La liste des demandes en cours.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Demandes en cours
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->

                <!-- <div id="toast_target"></div>
                <button ejs-button [isPrimary]="true" (click)="btnClick($event)">Show Toast</button>
                <ejs-toast #element (beforeOpen)="onBeforeOpen($event)" [position]='position'>
                    <ng-template #title>
                        <div>Matt sent you a friend request</div>
                    </ng-template>
                    <ng-template #content>
                        <div>Hey, wanna dress up as wizards and ride our hoverboards?</div>
                    </ng-template>
                </ejs-toast> -->

            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="allDemandes.length === 0">
                    <h5 class="text-center">Aucune demandes en cours</h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="allDemandes.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de la<br>demande</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Service</th>
                            <th class="text-center">Propositions</th>
                            <th class="text-center">Etat</th>
                            <!-- <th class="text-center">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let demande of allDemandes, let index = index">
                            <tr style="cursor: pointer;" (click)="goToNewTabDemande(demande._id, '/details-demande')">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                    ? demande?.user_transportor?.profile_user : 'profile.jpg')}}"
                                                        alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                                {{demande.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-transportor')">
                                                    {{demande?.user_transportor?.prenom_user}}
                                                    {{demande?.user_transportor?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.user_transportor?.email_user}}</div>
                                                <div class="widget-subheading">
                                                    {{demande?.user_transportor?.tel_user}}</div>
                                                <div
                                                    [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                    {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>


                                                <div class="widget-subheading"
                                                    *ngIf="demande?.flux != null && demande?.flux != ''">
                                                    <b>→ {{demande?.flux}}</b>
                                                </div>
                                                <!-- <div class="widget-subheading">
                                                {{(demande?.isDriver) ? "C'est un chauffeur" : "C'est un client"}}</div> -->
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{demande?.action_date_demande | date:'d MMM y HH:mm'}}</td>
                                <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                    <strong>{{demande?.user_location?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                    <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">
                                    <strong
                                        (click)="goToNewTab(demande?.type_vehicule, '/parc-transportors')">{{demande?.type_vehicule}}</strong><br>
                                    <strong>{{demande?.type_service}}</strong><br>
                                    <strong>{{demande?.type_products}}</strong><br>
                                    <strong *ngIf="demande?.price != null" style="color: tomato;">{{demande?.price}}
                                        DT</strong>
                                </td>
                                <td>
                                    <div>
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                            <li class="list-group-item"
                                                *ngFor="let driver of demande.driver_responses, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="40" height="40" class="rounded-circle"
                                                                src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + driver?.driver_id?.user_transportor?.profile_user}}"
                                                                alt="">
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading"
                                                                (click)="goToNewTab(driver?.driver_id?._id, '/detail-transportor')">
                                                                {{driver?.driver_id?.user_transportor?.prenom_user}}
                                                                {{driver?.driver_id?.user_transportor?.nom_user}}
                                                            </div>
                                                            <div class="widget-subheading">
                                                                {{driver?.driver_id?.user_transportor?.tel_user}}<br>
                                                                <div
                                                                    [class]="(driver?.etat_response=='pending') ? 'badge badge-warning' : 'badge badge-danger'">
                                                                    {{driver?.etat_response}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-xlg text-muted">
                                                                <span>{{driver?.proposition}} DT</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-{{statusDemandes[demande?.etat_demande]?.color}}">
                                        {{statusDemandes[demande?.etat_demande]?.label}}</div>
                                    <div *ngIf="demande?.etat_demande == 'canceled'">
                                        <b>{{demande?.msg_returned_cmd}}</b><br>
                                        <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                    </div>
                                </td>
                                <!-- <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button" [routerLink]="['/details-demande']"
                                                [queryParams]="{ demande: demande._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details demande
                                            </button>
                                        </div>
                                    </div>
                                </td> -->
                            </tr>
                            <tr *ngIf="demande.acceptedBy != null" style="background-color: rgb(146, 238, 207);">
                                <td colspan="3" class="text-center">
                                    <b>Transport accepté le {{demande?.acceptingDate | date:'d MMM y HH:mm'}}, par :
                                    </b>
                                </td>
                                <td colspan="4">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                    alt="">
                                            </div>
                                            <div class="widget-content-left mr-5">
                                                <div class="widget-heading"
                                                    (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-transportor')">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}
                                                </div>
                                                <div class="widget-subheading">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}
                                                </div>
                                            </div>
                                            <div class="widget-content-left">
                                                <!-- <div class="font-size-xlg text-muted"> -->
                                                <div class="badge badge-success">
                                                    Acceptée</div>
                                                <!-- <span>{{demande?.acceptedBy?.proposition}} DT</span> -->
                                                <!-- </div> -->
                                            </div>
                                            <div class="widget-content-right">
                                                <div class="font-size-xlg text-muted">
                                                    <span
                                                        style="color: rgb(71, 105, 255);">{{demande?.acceptedBy?.proposition}}
                                                        DT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div *ngIf="demande.bonusPromo!=null" class="font-size-xlg text-muted text-right">
                                        <span style="color: tomato;">Bonus: -{{demande?.bonusPromo}} DT</span>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="demande.isPlanified == true" style="background-color: yellowgreen;">
                                <td colspan="9" class="text-center"><b>Transport planifié le
                                        {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>