import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AffectationDialogComponent } from 'src/app/components/shared/dialogs/affectation-dialog/affectation-dialog.component';
import { SacsService } from '../../../services/sacs/sacs.service';

@Component({
  selector: 'app-sacs',
  templateUrl: './sacs.component.html',
  styleUrls: ['./sacs.component.css']
})
export class SacsComponent implements OnInit {

  villes = ["ALL", 'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'];
  selectedVille: string = 'ALL';
  sacsListByVille: any = [];

  constructor(private sacsServices: SacsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.listSacs();
  }

  selectChangeVille(event) {
    this.selectedVille = event.target.value
  }

  async listSacs() {
    await this.sacsServices.allSacs(this.selectedVille).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.sacsListByVille = res;
      }
    });
  }

  async viderSac(idSac) {
    if (confirm('Etes vous sûre?'))
      await this.sacsServices.emptySac(idSac).then((res: any) => {
        if (res && res == true) {
          this.listSacs()
        }
      });
  }
  searchSacByVille() {
    this.listSacs()
  }

  async affectSacToLouagiste(idSac, destinationSac) {
    // console.log("amineeeeeeeeee")
    const confirmDialog = this.dialog.open(AffectationDialogComponent, {
      data: {
        idSac: idSac,
        destinationSac: destinationSac,
        user: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data.user)
        // console.log("idSac : ")
        // console.log(idSac)
        this.sacsServices.affectSacToLouagiste(idSac, data.user).then((res: any) => {
          // console.log("affectSacToLouagiste : ");
          // console.log(res);
          if (res && !res.response) {
            alert(res.msg)
          }
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        this.listSacs();
      }
    });

  }

}
