import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-display-demandes',
  templateUrl: './display-demandes.component.html',
  styleUrls: ['./display-demandes.component.css']
})
export class DisplayDemandesComponent implements OnInit {

  statusDemandes: any = [];
  
  constructor(public dialogRef: MatDialogRef<DisplayDemandesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )
  }

}
