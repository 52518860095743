import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SacsService } from 'src/app/services/sacs/sacs.service';
import { QRCodeModule } from 'angularx-qrcode';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-detail-sac',
  templateUrl: './detail-sac.component.html',
  styleUrls: ['./detail-sac.component.css']
})


export class DetailSacComponent implements OnInit {

  sac: any = {}
  sacID: string = '';
  
  statusCmds: any = [];

  constructor(
    private route: ActivatedRoute,
    private cmdsServices: CommandesService,
    private sacsServices: SacsService,
  ) { }

  ngOnInit(): void {
    this.cmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.sacID = params.sac;
      this._fetchSac();
    });
  }

  _fetchSac() {
    // console.log(cid);
    this.sacsServices.getSac(this.sacID).then((d) => {
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.sac = d;
      } else {
        alert('Aucune sac valide');
        location.href = '/sacs';
      }
    });
  }

  generatePDF() {
    var divContents = document.getElementById("qrcode").innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF"><div class="container invoice"><center><strong><h1>'
    );

    a.document.write(divContents);
    a.document.write('</h1></strong></center></div></body></html>');
    a.document.close();
    a.print();
  }

}
